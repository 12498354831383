import { useCallback, useEffect, useRef, useState } from 'react';

// Для остановки перерисовок при необходимости
export function useMounted(): () => boolean {
  const unmounted = useRef(false);
  useEffect(
    () => () => {
      unmounted.current = true;
    },
    []
  );
  return useCallback(() => !unmounted.current, []);
}

// Альтернатива появлению value в списке зависимостей - неизменная функция для получения актуального value
export function useGetter<T>(value: T): () => T {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return useCallback(() => valueRef.current, []);
}

type Callback<Result, Args> = (...args: Args[]) => Promise<Result>;

// Состояние любых асинхронных операций
export function useProcessing(initial = false) {
  const [processing, setProcessing] = useState(initial);
  const getProcessing = useGetter(processing);
  const isMounted = useMounted();

  // для синхронного вызова, например, внутри useEffect
  const start = useCallback(() => setProcessing(true), []);
  const stop = useCallback(() => {
    if (isMounted()) {
      setProcessing(false);
    }
  }, []);

  // для асинхронного вызова, например, внутри useCallback
  const wrap = useCallback(
    <T, A>(func: Callback<T, A>) =>
      async (...args: Array<A>) => {
        if (!getProcessing()) {
          start();
          const result = await func(...args);
          stop();
          return result;
        }
        return null;
      },
    []
  );
  return { processing, start, stop, wrap };
}

export function useScript(src: string, condition = true) {
  const [status, setStatus] = useState<'loading' | 'idle' | 'ready' | 'error'>(src ? 'loading' : 'idle');

  useEffect(() => {
    const script = document.createElement('script');
    const setStateFromEvent = (event: { type: string }) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    if (condition) {
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);
      const setAttributeFromEvent = (event: { type: string }) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);

      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
    } else {
      setStatus('ready');
    }

    return () => {
      script.removeEventListener('load', setStateFromEvent);
      script.removeEventListener('error', setStateFromEvent);
    };
  }, [src]);

  return status;
}
