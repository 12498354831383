import styles from './spinner.module.css';

import classNames from 'classnames';

export enum SpinnerColors {
  gray = 'gray',
  white = 'white',
  blue = 'blue',
  red = 'red'
}

type TSizes = 32 | 24 | 16;

interface SpinnerProps {
  classname?: string;
  color?: SpinnerColors;
  size?: TSizes;
}

export function Spinner({ classname, color = SpinnerColors.blue, size = 24 }: SpinnerProps) {
  return (
    <div className={classNames(styles.root, classname)}>
      <svg className={classNames(styles.spinner, styles[color])} width={size} height={size}>
        <circle className={styles.circle} cx='50%' cy='50%' r='44%' />
      </svg>
    </div>
  );
}
