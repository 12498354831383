import { RefObject, useEffect } from 'react';

export const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  opened: boolean,
  handler: (event: MouseEvent) => void
) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (e.target instanceof Node && opened && ref && ref.current && e.target && !ref.current.contains(e.target)) {
        handler(e);
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, opened, handler]);
};
