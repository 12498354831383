// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import classNames from 'classnames';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { APPLY_FILTER_BUTTON_TEXT } from 'core/constants/filters';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { getCalendarText } from 'core/utils/filters/filters-info';

import { FilterControlsContext } from 'contexts/filters/filter-control';

import { useHasChekedCalendar } from 'hooks/filters';

import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { CalendarModal } from 'components/search/controls/calendar/calendar-modal/calendar-modal';
import { RangeInfo } from 'components/search/controls/calendar/range-info/range-info';
import CalendarIcon from 'components/search/controls/icons/calendar.svg';

interface CalendarControlProps {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  mainHost: string;
  notFilter?: boolean;
  hasError?: boolean;
  noMinDuration?: boolean;
  noCheckValidation?: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

const defaultButtonText = 'Даты';

export const CalendarControl = ({
  filters,
  setFilters,
  mainHost,
  notFilter,
  hasError,
  noMinDuration,
  noCheckValidation,
  isLoading,
  setLoading
}: CalendarControlProps) => {
  const { calendar } = useContext(FilterControlsContext);
  const [dates, setDates] = useState(filters.dates);
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const hasCheckedCalendar = useHasChekedCalendar(filters);
  const [calendarError, setCalendarError] = useState<Optional<string>>(null);

  const footerChildren = (
    <div className={styles.calendarFooterChildren}>
      <RangeInfo dates={dates} calendarError={calendarError} />
    </div>
  );

  useEffect(() => {
    if (filters.dates.arrival && filters.dates.departure) {
      setButtonText(getCalendarText(filters.dates, notFilter ? 'long' : 'short'));
    } else {
      setButtonText(defaultButtonText);
    }
  }, [filters]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.notInFilters]: notFilter
      })}
    >
      <DropdownButton
        className={classNames(styles.btn, {
          [styles.tinyCalendarBtnText]: hasCheckedCalendar
        })}
        text={buttonText}
        icon={<CalendarIcon className={styles.icon} />}
        size={notFilter ? 'lg' : 'md'}
        isActive={calendar.opened}
        onClick={calendar.onOpen}
        hasError={hasError}
        isLoading={isLoading ? isLoading.calendar : false}
      />
      <CalendarModal
        btnText={APPLY_FILTER_BUTTON_TEXT}
        calendar={calendar}
        calendarError={calendarError}
        dates={dates}
        mainHost={mainHost}
        filters={filters}
        footerChildren={footerChildren}
        setCalendarError={setCalendarError}
        setDates={setDates}
        setFilters={setFilters}
        noMinDuration={noMinDuration}
        noCheckValidation={noCheckValidation}
        setLoading={setLoading}
      />
    </div>
  );
};
