import styles from './sleeping-places.module.css';

import { Counter } from 'components/common/counter/counter';
import { Text, TextColors } from 'components/common/text/text';

interface SleepingPlacesFilterProps {
  minBeds: number;
  onChangeMinBeds: (value: number) => void;
}

export const SleepingPlacesFilter = ({ minBeds, onChangeMinBeds }: SleepingPlacesFilterProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.variant}>
        <Text size={16} color={TextColors.black}>
          Кроватей не менее
        </Text>
        <Counter value={minBeds} onChange={onChangeMinBeds} limits={{ min: 1, max: 40 }} />
      </div>
    </div>
  );
};
