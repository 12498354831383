import { ParsedUrlQuery } from 'querystring';

import get from 'lodash/get';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import { GetServerSidePropsContext } from 'next';

import { DEFAULT_FILTERS } from 'core/constants/default-values';
import { Currency } from 'core/entities';
import { Filters } from 'core/entities/filters';
import { Point } from 'core/entities/geo';
import { BathroomType, KitchenType } from 'core/entities/housing';
import { SearchFilters, SearchPageParams } from 'core/entities/search';
import {
  getBuildingTypeFromApiFilters,
  getBuildingTypeFromQuery,
  getBuildingTypesFromFilters,
  getDatesFromApiFilters,
  getDatesFromQuery,
  getFeaturesFromApiFilters,
  getFeaturesFromQuery,
  getGuestsFromApiFilters,
  getMapFromApiFilters,
  getMapFromQuery,
  getPriceFromApiFilters,
  getPriceFromQuery,
  getRoomsExtraOptionsFromApiFilters,
  getRoomsExtraOptionsFromQuery,
  getRoomsFormApiFilters,
  getRoomsFromFilters,
  getRoomsFromQuery,
  getSelectedGuestsFromQuery,
  getSleepingPlacesFromFilters
} from 'core/utils/filters/index';

export const setQueryParamsFromFilters = (filters: Filters, currency: Currency) => {
  const checkPrice =
    filters.prices.filtered.min !== currency.minPrice || filters.prices.filtered.max !== currency.maxPrice;

  const adultsParam = get(filters, 'guests.adults', 1);
  // Метка добавления нового фильтра
  const params = {
    arrival: get(filters, 'dates.arrival', null),
    departure: get(filters, 'dates.departure', null),

    minPrice: checkPrice ? filters.prices.filtered.min : null,
    maxPrice: checkPrice ? filters.prices.filtered.max : null,

    buildingType: getBuildingTypesFromFilters(filters.buildingTypes),
    rooms: getRoomsFromFilters(filters.rooms),
    beds: filters.minBeds > 1 ? filters.minBeds : null,
    adults: filters.guestsSelected ? adultsParam : null,
    baby: get(filters, 'guests.babies', null),
    children: get(filters, 'guests.children', null),
    sleepingPlaces: getSleepingPlacesFromFilters(filters.guests, filters.guestsSelected),
    guestsSelected: filters.guestsSelected,

    kitchenType: filters.roomsExtraOptions?.kitchen ? `${KitchenType.privated},${KitchenType.shared}` : null,
    bathroomType: filters.roomsExtraOptions?.bathroom ? BathroomType.privated : null,

    instantBooking: filters.features.instantBooking,
    reviewGood: filters.features.reviewGood,
    studio: filters.features.studio,
    internet: filters.features.internet,
    documents: filters.features.reportDocs,
    parking: filters.features.parking,
    party: filters.features.parties,
    smoking: filters.features.smoking,
    pets: filters.features.animals,
    airconditioner: filters.features.conditioner,
    washingMachine: filters.features.washingMachine,
    refrigerator: filters.features.fridge,
    cooker: filters.features.cooker,
    teapot: filters.features.teapot,
    hairDryer: filters.features.hairDryer,
    iron: filters.features.iron,
    spa: filters.features.spa,
    sauna: filters.features.sauna,
    bbq: filters.features.bbq,
    pool: filters.features.pool,
    billiard: filters.features.billiard,
    karaoke: filters.features.karaoke,
    fireplace: filters.features.fireplace,
    babies: filters.features.babies,
    babyBed: filters.features.babyBed,

    minLat: get(filters, 'map.minLat', null),
    minLng: get(filters, 'map.minLng', null),
    maxLat: get(filters, 'map.maxLat', null),
    maxLng: get(filters, 'map.maxLng', null),
    lng: get(filters, 'map.lng', null),
    lat: get(filters, 'map.lat', null),
    zoom: get(filters, 'map.zoom', null),
    boundaryKey: get(filters, 'map.boundaryKey', null),

    pointName: filters.pointName
  };

  return omitBy(params, (item) => !item);
};

// eslint-disable-next-line complexity
export const getFiltersFromQueryParams = (query: SearchPageParams, currency: Currency): Filters => {
  return {
    buildingTypes: getBuildingTypeFromQuery(query),
    dates: getDatesFromQuery(query),
    features: getFeaturesFromQuery(query),
    rooms: getRoomsFromQuery(query),
    roomsExtraOptions: getRoomsExtraOptionsFromQuery(query),
    guests: {
      adults: Number(query.adults) ? Number(query.adults) : DEFAULT_FILTERS.guests.adults,
      children: Number(query.children) ? Number(query.children) : DEFAULT_FILTERS.guests.children,
      babies: Number(query.baby) ? Number(query.baby) : DEFAULT_FILTERS.guests.babies
    },
    guestsSelected: getSelectedGuestsFromQuery(query),
    sleepingPlaces: Number(query.sleepingPlaces) ? Number(query.sleepingPlaces) : DEFAULT_FILTERS.sleepingPlaces,
    minBeds: Number(query.beds) ? Number(query.beds) : DEFAULT_FILTERS.minBeds,
    prices: getPriceFromQuery(query, currency),
    map: getMapFromQuery(query),
    pointName: query.pointName ? query.pointName : ''
  };
};

export const getFiltersFromApiFilters = (
  apiFilters: SearchFilters,
  query: SearchPageParams,
  currency: Currency,
  seoFilers: SearchFilters | null = null
): Partial<Filters> => {
  return {
    dates: getDatesFromApiFilters(apiFilters, query),
    buildingTypes: getBuildingTypeFromApiFilters(apiFilters, query),
    rooms: getRoomsFormApiFilters(apiFilters, query),
    features: getFeaturesFromApiFilters(apiFilters, query),
    minBeds: apiFilters.beds || query.beds || DEFAULT_FILTERS.minBeds,
    sleepingPlaces:
      Number(apiFilters.sleeping_places) || Number(query.sleepingPlaces) || DEFAULT_FILTERS.sleepingPlaces,
    prices: getPriceFromApiFilters(apiFilters, query, currency),
    guests: getGuestsFromApiFilters(apiFilters, query),
    roomsExtraOptions: getRoomsExtraOptionsFromApiFilters(apiFilters, query),
    map: getMapFromApiFilters(apiFilters, query, seoFilers),
    guestsSelected: apiFilters.guests && Number(apiFilters.guests.adults) > 0 ? true : DEFAULT_FILTERS.guestsSelected
  };
};

// поддержка устаревших параметров
const synonyms = {
  currencyId: 'currency',
  currency_id: 'currency',
  price: 'maxPrice',
  min_price: 'minPrice',
  type: 'buildingType',
  building_type: 'buildingType',
  building_types: 'buildingType',
  buildingTypes: 'buildingType',
  sleeping_places: 'sleepingPlaces',
  verified_photos: 'verified',
  report_documents: 'documents',
  reportDocs: 'documents',
  city_id: 'city',
  region_id: 'region',
  regions: 'region',
  country_id: 'country'
};

export const getQueryParamsFromUrl = (searchUrl: string): SearchPageParams => {
  const urlSearchParams = new URLSearchParams(searchUrl);
  const query: { [key: string]: string } = {};

  urlSearchParams.forEach((value, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    query[get(synonyms, key, key)] = value;
  });

  return query;
};

export const getQueryParamsWithUrl = (context: GetServerSidePropsContext): ParsedUrlQuery => {
  const query = context.query;
  query.referer = get(context, 'req.headers.referer', '');
  query.utm_url = get(context, 'req.url', '');

  return query;
};

export const getQueryWithoutRouterParams = (query: ParsedUrlQuery) => {
  return omit(query, ['country', 'region', 'search']);
};

export const getFiltersWithoutMapParams = (filters: Filters) => {
  return omit(filters, ['map']);
};

export const getPointFromQuery = (query: ParsedUrlQuery): Optional<Point> => {
  let point: Optional<Point> = null;
  if (query.lat && query.lng) {
    point = {
      lat: Number(query.lat),
      lng: Number(query.lng)
    };
  }
  return point;
};

export const getBoxFromQuery = (query: ParsedUrlQuery): Optional<number[][]> => {
  let box: Optional<number[][]> = null;
  if (query.minLat && query.minLng && query.maxLat && query.maxLng) {
    box = [
      [Number(query.minLat), Number(query.minLng)],
      [Number(query.maxLat), Number(query.maxLng)]
    ];
  }
  return box;
};

export const getSearchQueryFromApiFilters = (
  apiFilters: SearchFilters,
  query: SearchPageParams,
  currency: Currency
) => {
  return setQueryParamsFromFilters(getFiltersFromApiFilters(apiFilters, query, currency) as Filters, currency);
};
