import get from 'lodash/get';

import { Suggest, SuggestListItem } from 'core/entities/suggest';
import { APIService } from 'core/services/index';

export class SuggestsService extends APIService {
  public async fetchSuggests(query: string): Promise<{ suggests: Array<SuggestListItem> }> {
    const response = await this.execute('/api/v2/suggests', 'GET', { params: { query } });
    return {
      suggests: get(response, 'data.suggests', [])
    };
  }

  public async fetchSuggest(key: string): Promise<Suggest> {
    const response = await this.execute(`/api/v2/suggests/${key}`);
    return get(response, 'data.suggest', {}) as Suggest;
  }
}
