import { ReactElement } from 'react';

const openTag = '<!--noindex-->';
const closeTag = '<!--/noindex-->';

interface NoindexProps {
  children?: ReactElement;
}

export const Noindex = ({ children }: NoindexProps) => {
  return (
    <div data-nosnippet>
      <span dangerouslySetInnerHTML={{ __html: openTag }} />
      {children}
      <span dangerouslySetInnerHTML={{ __html: closeTag }} />
    </div>
  );
};
