import React from 'react';

import { NOOP } from 'core/utils/NOOP';

interface AppVirtualLinkContextType {
  onAppRedirect: (href: string) => void;
}

export const AppVirtualLinkContext = React.createContext<AppVirtualLinkContextType>({ onAppRedirect: NOOP });

interface AppVirtualLinkProviderProps {
  children: React.ReactElement;
}

export const AppVirtualLinkProvider = ({ children }: AppVirtualLinkProviderProps) => {
  const ref = React.useRef<HTMLAnchorElement>(null);

  const onAppRedirect = React.useCallback((href: string) => {
    if (ref.current) {
      ref.current.href = href;
      ref.current.click();
    }
  }, []);

  return (
    <AppVirtualLinkContext.Provider value={{ onAppRedirect }}>
      <>
        {children}
        <a className='visually-hidden' ref={ref} />
      </>
    </AppVirtualLinkContext.Provider>
  );
};

export const useAppRedirect = () => {
  const { onAppRedirect } = React.useContext(AppVirtualLinkContext);
  return { onAppRedirect };
};
