import styles from './rooms.module.css';

import classNames from 'classnames';

import { RoomsFilters } from 'core/entities/filters';
import { getMulticheckboxClasses } from 'core/utils/get-multicheckbox-classes';

import { Checkbox, OnChangeCheckbox } from 'components/common/checkbox/checkbox';

interface RoomsFilterProps {
  className?: string;
  rooms: RoomsFilters;
  onChange: OnChangeCheckbox;
}

export const RoomsFilter = ({ className, rooms, onChange }: RoomsFilterProps) => {
  const checkboxes = [!!rooms.one, !!rooms.two, !!rooms.three, !!rooms.four, !!rooms.five];
  const classes = {
    default: styles.variant,
    hasCheckOnLeft: styles.hasCheckOnLeft,
    hasCheckOnRight: styles.hasCheckOnRight
  };

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.title}>Число комнат</div>
      <div className={styles.variants}>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 0)}
          variant='button'
          id='one'
          value='1'
          checked={rooms.one}
          onToggle={onChange}
        >
          1
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 1)}
          variant='button'
          id='two'
          value='2'
          checked={rooms.two}
          onToggle={onChange}
        >
          2
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 2)}
          variant='button'
          id='three'
          value='3'
          checked={rooms.three}
          onToggle={onChange}
        >
          3
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 3)}
          variant='button'
          id='four'
          value='4'
          checked={rooms.four}
          onToggle={onChange}
        >
          4
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 4)}
          variant='button'
          id='five'
          value='5'
          checked={rooms.five}
          onToggle={onChange}
        >
          5+
        </Checkbox>
      </div>
    </div>
  );
};
