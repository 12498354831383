import styles from './back-to-admin-button.module.css';

import classNames from 'classnames';

import CustomLink from 'components/common/link/link';

interface BackToAdminButtonProps {
  className?: string;
  userId: number;
}

export const BackToAdminButton = (props: BackToAdminButtonProps) => {
  const href = `/moderation/users/id${props.userId}/?__unimpersonate=True`;
  return (
    <CustomLink className={classNames(styles.root, props.className)} href={href} variant='light-button' external>
      Вернуться в админку
    </CustomLink>
  );
};
