import styles from './suggest.module.css';

import classNames from 'classnames';
import { useCallback } from 'react';

import { SuggestListItem } from 'core/entities/suggest';

interface SuggestProps {
  suggest: SuggestListItem;
  index: number;
  currentIndex?: number;
  onClick: (suggest: SuggestListItem) => void;
  onMouseEnter?: (index: number) => void;
}

export const Suggest = (props: SuggestProps) => {
  const handleClick = useCallback(() => {
    props.onClick(props.suggest);
  }, [props.suggest]);

  const handleMouseEnter = useCallback(() => {
    if (props.onMouseEnter) {
      props.onMouseEnter(props.index);
    }
  }, [props.suggest]);

  return (
    <li
      className={classNames(styles.root, { [styles.focus]: props.index === props.currentIndex })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      {props.suggest.name}
      {props.suggest.description && <span className={styles.description}>{props.suggest.description}</span>}
    </li>
  );
};
