import styles from './prices.module.css';

import classNames from 'classnames';

import { Currency } from 'core/entities';
import { Filters } from 'core/entities/filters';

import { Slider } from 'components/common/slider/slider';

interface PricesFilterProps {
  className?: string;
  value: Array<number>;
  currencies: {
    current: Currency;
    primary: Currency;
  };
  filters: Filters;
  isLandingWithPrice: boolean;
  onChange: (values: Array<number>) => void;
}

export const PricesFilter = (props: PricesFilterProps) => {
  return (
    <div className={classNames(styles.root, props.className)}>
      <Slider
        className={styles.slider}
        value={props.value}
        min={props.filters.prices.range.min}
        max={props.filters.prices.range.max}
        currencies={props.currencies}
        isLandingWithPrice={props.isLandingWithPrice}
        onChange={props.onChange}
      />
    </div>
  );
};
