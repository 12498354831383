import styles from './day.module.css';

import classNames from 'classnames';
import { useCallback } from 'react';

import { useGetter } from 'hooks';

import { useSelectionController } from 'hooks/date-selection';
import { useTimer } from 'hooks/use-timer';

import DayLeftWave from 'components/calendar/icons/day-left-wave.svg';
import DayRightWave from 'components/calendar/icons/day-right-wave.svg';

interface DayProps {
  className?: string;
  date: Date;
  first?: boolean;
  last?: boolean;
  ragged?: boolean;
  hasError?: boolean;
}

enum Animation {
  disabled = 0,
  success = 1,
  error = 2
}

export const Day = (props: DayProps) => {
  const selection = useSelectionController(props.date);
  const animation = useTimer(Animation.disabled);
  const dayOfMonth = props.date.getDate();

  const getAvailable = useGetter(selection.isAvailable);

  const handleClick = useCallback(() => {
    animation.start(150, getAvailable() ? Animation.success : Animation.error);
    selection.onClick();
  }, [selection.onClick]);

  const dayClasses = classNames(styles.root, props.className, {
    [styles.weekend]: [0, 6].includes(props.date.getDay()),
    [styles.firstDay]: props.first,
    [styles.lastDay]: props.last,
    [styles.raggedLeftBorder]: props.ragged && dayOfMonth === 1,
    [styles.raggedRightBorder]: props.ragged && dayOfMonth !== 1,
    [styles.departureDay]: selection.isDeparture,
    [styles.arrivalDay]: selection.isArrival,
    [styles.disabledDay]: selection.isDisabled,
    [styles.isStartOfBooking]: selection.isStartOfBooking,
    [styles.hoveredDay]: selection.isHovered,
    [styles.selectedDay]: selection.isSelected,
    [styles.availableDay]: selection.isAvailable,
    [styles.lastHoveredDay]: selection.isLastHovered,
    [styles.animatedAvailableDay]: animation.active === Animation.success,
    [styles.animatedBlockedDay]: animation.active === Animation.error,
    [styles.error]: props.hasError
  });

  return (
    <span className={dayClasses} onClick={handleClick} onMouseOver={selection.onHover}>
      {props.first ? <DayLeftWave className={classNames(styles.wave, styles.waveLeft)} /> : null}
      {dayOfMonth}
      {props.last ? <DayRightWave className={classNames(styles.wave, styles.waveRight)} /> : null}
    </span>
  );
};
