import styles from './action-item.module.css';

import classNames from 'classnames';
import { ReactNode } from 'react';

import CustomLink from 'components/common/link/link';
import { Text, TextColors } from 'components/common/text/text';

interface ActionItemProps {
  href: string;
  text: string;
  icon: ReactNode;
  external?: boolean;
  isActive?: boolean;
  isHide?: boolean;
  isFlatOwner?: boolean;
  hasMark?: boolean;
  isCentral?: boolean;
  iconWithMark?: ReactNode;
}

export function ActionItem(props: ActionItemProps) {
  const {
    href,
    text,
    icon,
    external = false,
    isActive = false,
    hasMark = false,
    isHide = false,
    isCentral,
    iconWithMark
  } = props;

  if (isHide) {
    return null;
  }

  return (
    <li
      className={classNames(
        styles.root,
        { [styles.mark]: hasMark },
        { [styles.active]: isActive },
        { [styles.isFlatOwner]: props.isFlatOwner },
        { [styles.central]: isCentral }
      )}
      title={text}
    >
      {/* TODO: убрать "external", когда основная навигация будет через next роутер */}
      <CustomLink
        className={classNames(styles.link, { [styles.central]: isCentral })}
        href={href}
        external={external}
        variant={isCentral ? 'white' : 'blue'}
      >
        <>
          <div className={styles.icon}>{isCentral && hasMark ? iconWithMark : icon}</div>
          <Text
            className={styles.text}
            size={9}
            desktopSize={10}
            color={isCentral ? TextColors.white : TextColors.blue}
          >
            {text}
          </Text>
        </>
      </CustomLink>
    </li>
  );
}
