import styles from './price.module.css';

import classNames from 'classnames';

import { RUS_ID } from 'core/constants/currency';
import { Currency } from 'core/entities';

export function formatPriceModern(value: number) {
  return <>{`${value}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1\u202F')}</>;
}

export function formatPriceLegacy(value: number, min = 4) {
  const ceilValue = Math.ceil(value);
  const price = ceilValue.toString();
  let result = <span>{ceilValue}</span>;

  if (price.length > min) {
    result = (
      <span>
        <span className={styles.value}>{formatPriceLegacy(parseInt(price.substr(0, price.length - 3), 10), 3)}</span>
        {price.substr(price.length - 3, 3)}
      </span>
    );
  }

  return result;
}

interface PriceProps {
  className?: string;
  currency: Currency;
  value: number;
  discountValue?: number;
  formatted?: boolean;
  showCurrency?: boolean;
  renderType?: 'legacy' | 'modern';
}

export const Price = ({
  className,
  currency,
  value,
  discountValue,
  formatted = true,
  showCurrency = true,
  renderType = 'legacy'
}: PriceProps) => {
  let currencyElement;
  if (currency.id === RUS_ID) {
    currencyElement = <span className={classNames(styles.currency, className)}>&#x202F;₽</span>;
  } else {
    currencyElement = <span className={classNames(styles.currency, className)}>&#x202F;{currency.entity}</span>;
  }

  let formattedDiscountValue = null;
  if (discountValue && discountValue !== value) {
    formattedDiscountValue = (
      <span>
        <span className={styles.discount}>{discountValue}</span>
        <span>&#x202F;</span>
      </span>
    );
  }
  let formattedValue = <span itemProp='priceRange'>{value}</span>;
  if (formatted) {
    formattedValue = renderType === 'modern' ? formatPriceModern(value) : formatPriceLegacy(value, 4);
  }

  return (
    <span className={styles.price}>
      <span className={classNames(styles.value, className, { [styles.priceModern]: renderType === 'modern' })}>
        {formattedDiscountValue} {formattedValue}
        {showCurrency && currencyElement}
      </span>
    </span>
  );
};
