export const dateFormat = {
  dayFullMonthYear: 'd MMMM yyyy',
  dayShortMonthYear: 'd.MM.yyyy',
  dayMonth: 'd MMMM',
  day: 'd',
  weekDay: 'EEEE',
  iso: 'yyyy-MM-dd',
  log: 'd.MM.yyyy HH:mm:ssSS',
  logWithoutSeconds: 'HH:mm d.MM.yyyy',
  hoursMinutesFullDay: 'H:mm',
  dayMonthYear: 'd MMM y',
  dayShortMonth: 'd MMM',
  fullWeekDay: 'cccc',
  shortWeekDay: 'cccccc',
  fullDateShortYear: 'dd.MM.yy'
};
