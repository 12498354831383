import styles from './dropdown.module.css';

import classNames from 'classnames';
import * as React from 'react';

import { useOnClickOutside } from 'hooks/use-on-click-outside';

const DROPDOWN_MAX_HEIGHT = 280;

export function useDropdown(dropdownRef: React.RefObject<HTMLDivElement>, defaultOpened = false) {
  const [opened, toggleOpened] = React.useState(defaultOpened);
  const [canOpenedBottom, setCanOpenedBottom] = React.useState(true);
  const [openedTop, setOpenedTop] = React.useState(false);

  React.useEffect(() => {
    const checkPosition = () => {
      if (!dropdownRef.current) {
        return;
      }
      const box = dropdownRef.current.getBoundingClientRect();
      const offsetBottom = typeof window === 'object' ? window.innerHeight - box.bottom : 0;

      if (DROPDOWN_MAX_HEIGHT <= offsetBottom) {
        setCanOpenedBottom(true);
      } else {
        setCanOpenedBottom(false);
      }
    };
    checkPosition();
    document.addEventListener('scroll', checkPosition);
    return () => {
      document.removeEventListener('scroll', checkPosition);
    };
  }, []);

  const getCurrentPosition = React.useCallback(() => {
    if (canOpenedBottom) {
      setOpenedTop(false);
    } else {
      setOpenedTop(true);
    }
  }, [canOpenedBottom]);

  const onOpen = React.useCallback(() => {
    getCurrentPosition();
    toggleOpened(true);
  }, [opened, getCurrentPosition]);

  const onClose = React.useCallback(() => {
    toggleOpened(false);
  }, [opened]);

  useOnClickOutside(dropdownRef, opened, onClose);

  return {
    opened,
    openedTop,
    onOpen,
    onClose
  };
}

interface DropdownProps {
  children?: React.ReactChild;
  className?: string;
  opened?: boolean;
  openedTop?: boolean;
  openedLeft?: boolean;
  block?: boolean;
}

export const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>((props, ref) => {
  const { children, className, opened = false, openedTop, openedLeft = false, block = false } = props;

  const classes = classNames(
    styles.root,
    className,
    { [styles.opened]: opened },
    { [styles.top]: openedTop },
    { [styles.left]: openedLeft },
    { [styles.block]: block }
  );

  return (
    <div className={classes} ref={ref}>
      {opened && children}
    </div>
  );
});
