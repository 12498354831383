import styles from './filters-header.module.css';

import classNames from 'classnames';
import { useCallback, useContext } from 'react';

import { DISABLE_MAP } from 'core/constants/yandex-api';
import { City } from 'core/entities/geo';

import { CommonContext } from 'contexts/common';
import { FiltersContext } from 'contexts/filters/filters';
import { SearchBarContext } from 'contexts/search/search-bar';

import { useFlatsRoutes } from 'hooks/router/use-flats-routes';
import { useFixFiltersHeader } from 'hooks/use-fix-filters-header';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { FiltersToggle } from 'components/layout/filters-header/filters-toggle/filters-toggle';
import { Actions } from 'components/layout/header/actions/actions';
import { Logo } from 'components/layout/header/logo/logo';
import { SearchFilters } from 'components/search/search-filters';
import { MapButton } from 'components/search-bar/map-button/map-button';
import { SearchBar } from 'components/search-bar/search-bar';

interface FiltersHeaderProps {
  className?: string;
  mainHost: string;
  city?: Optional<City>;
}

export const FiltersHeader = (props: FiltersHeaderProps) => {
  const { context } = useContext(CommonContext);
  const { filters } = useContext(FiltersContext);
  const { showFilters, setShowFilters } = useContext(SearchBarContext);
  const { isFixed, isHidden } = useFixFiltersHeader();
  const { isMapPage } = useFlatsRoutes(props.mainHost);
  const { isMobile } = useBreakpoints();

  if (context && context.isApp) {
    return null;
  }

  const handleToggleFilters = useCallback(() => {
    setShowFilters(true);
  }, []);

  return (
    <header
      className={classNames(
        styles.root,
        props.className,
        { [styles.isMapPage]: isMapPage },
        { [styles.hideFilters]: isMapPage && isMobile && !showFilters },
        { [styles.fixed]: isFixed },
        { [styles.hidden]: isHidden }
      )}
      id='fixed-header'
    >
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <Logo />
        </div>
        <div className={styles.content}>
          <SearchBar classname={styles.searchBar} mainHost={props.mainHost} city={props.city}>
            {DISABLE_MAP || isMapPage ? (
              <div />
            ) : (
              <MapButton className={styles.mobileMapButton} mainHost={props.mainHost} filters={filters} />
            )}
          </SearchBar>
        </div>
        <div className={styles.right}>
          <Actions />
        </div>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersWrapper}>
          <SearchFilters className={styles.filters} mainHost={props.mainHost} />
          {!DISABLE_MAP && <MapButton className={styles.mapButton} mainHost={props.mainHost} filters={filters} />}
        </div>
      </div>
      <FiltersToggle className={styles.filtersToggle} onClick={handleToggleFilters} />
    </header>
  );
};
