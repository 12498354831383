import styles from './range-info.module.css';

import classNames from 'classnames';
import { useCallback } from 'react';

import { WORD_FORM_FULL_DAYS } from 'core/constants/word-forms';
import { DatesFilters } from 'core/entities/filters';
import { longDateRangeFormat } from 'core/utils/calendar';
import { WordForms } from 'core/utils/word-forms';

import { useBreakpoints } from 'hooks/use-window-dimensions';

import { Text, TextColors } from 'components/common/text/text';

interface RangeInfoProps {
  dates: DatesFilters;
  calendarError?: Optional<string>;
  withSkipBtn?: boolean;
}

export const RangeInfo = ({ dates, calendarError, withSkipBtn = false }: RangeInfoProps) => {
  if (!dates.arrival || !dates.departure || !dates.duration) {
    return null;
  }

  const { isMobile } = useBreakpoints();
  const formatDuration = useCallback(() => {
    if (dates.duration) {
      return new WordForms(WORD_FORM_FULL_DAYS.ONE, WORD_FORM_FULL_DAYS.FEW, WORD_FORM_FULL_DAYS.MANY).getPlural(
        dates.duration,
        '\xa0',
        true
      );
    }
    return '';
  }, []);

  const datesText = longDateRangeFormat(new Date(dates.arrival), new Date(dates.departure)) + (isMobile ? ', ' : ' ');
  const textHtmlElement = withSkipBtn ? 'p' : 'span';

  return (
    <div className={styles.root}>
      {calendarError ? (
        <Text size={14} color={TextColors.error}>
          {calendarError}
        </Text>
      ) : (
        <>
          <Text className={classNames({ [styles.compactSize]: withSkipBtn })} As={textHtmlElement} size={14}>
            {datesText}
          </Text>
          <Text className={classNames({ [styles.compactSize]: withSkipBtn })} As={textHtmlElement} size={14}>
            {formatDuration()}
          </Text>
        </>
      )}
    </div>
  );
};
