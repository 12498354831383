import styles from './slim-footer.module.css';

import classNames from 'classnames';
import pick from 'lodash/pick';

import { FooterLinksSection } from 'core/entities';

import CustomLink from 'components/common/link/link';
import { FooterCopyright } from 'components/layout/footer/footer-copyright/footer-copyright';

interface SlimFooterProps {
  className?: string;
  sections: Array<FooterLinksSection>;
}

export const SlimFooter = (props: SlimFooterProps) => {
  const links = props.sections
    .map((section) => {
      return pick(section, 'links').links;
    })
    .flat();

  return (
    <footer className={classNames(styles.root, props.className)} id='footer'>
      <div className={styles.container}>
        <FooterCopyright className={styles.copy} slim />
        <ul className={styles.links}>
          <li className={styles.linkItem}>
            <CustomLink href='/info/legal/' variant='black' underlined>
              Правовая информация
            </CustomLink>
          </li>
          {links.map((link) => (
            <li className={styles.linkItem} key={link.codeName}>
              <CustomLink href={link.url} variant='black' underlined>
                {link.name}
              </CustomLink>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};
