/*
Хук вешает обработчик на скролл только для хедера с фильтрами
*/

import { useEffect, useState } from 'react';

import { useBreakpoints } from 'hooks/use-window-dimensions';

export const useFixFiltersHeader = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);

  const { isMobile, isTablet, isSmallDesktop } = useBreakpoints();

  useEffect(() => {
    let scrollValue = 82;
    if (isMobile) {
      scrollValue = 118;
    }
    if (isTablet) {
      scrollValue = 128;
    }
    if (isSmallDesktop) {
      scrollValue = 82;
    }

    function handleScroll() {
      const scrollTop = (typeof window === 'object' && window.pageYOffset) || 0;
      if (scrollTop === 0) {
        setIsFixed(false);
        setIsHidden(false);
      } else if (scrollTop > prevScroll && scrollTop > scrollValue) {
        setIsHidden(true);
      } else if (scrollTop < prevScroll && scrollTop > scrollValue) {
        setIsFixed(true);
        setIsHidden(false);
      }
      setPrevScroll(scrollTop);
    }

    if (typeof window === 'object') {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (typeof window === 'object') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [prevScroll, isMobile, isTablet, isSmallDesktop]);

  return { isFixed, isHidden };
};
