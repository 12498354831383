import styles from './calendar.module.css';

import classNames from 'classnames';
import { RefObject, useContext, useMemo } from 'react';

import { futureMonths } from 'core/utils/calendar';

import { WidgetContext } from 'contexts/calendar';

import { Month } from 'components/calendar/month/month';

interface CalendarProps {
  className?: string;
  refObject?: RefObject<HTMLDivElement>;
  containsError?: boolean;
}

export const Calendar = (props: CalendarProps) => {
  const months = useMemo(() => futureMonths(), []);
  const className = classNames(styles.root, props.className, { [styles.error]: props.containsError });
  const { arrival } = useContext(WidgetContext);

  return (
    <div className={className} ref={props.refObject}>
      {months.map((start, idx) => (
        <Month
          key={`month_${start.valueOf()}`}
          start={start}
          first={!idx}
          current={
            `${arrival?.getMonth()}_${arrival?.getFullYear()}` === `${start.getMonth() + 1}_${start.getFullYear()}`
          }
          containsError={props.containsError}
        />
      ))}
    </div>
  );
};
