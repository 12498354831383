import { useEffect, useState } from 'react';

import { Currency } from 'core/entities';
import { Filters } from 'core/entities/filters';

export const useHasChekedCalendar = (filters: Filters) => {
  const [hasChekedCalendar, setHasChoiceCalendar] = useState(false);
  useEffect(() => {
    if (filters.dates.arrival && filters.dates.departure) {
      setHasChoiceCalendar(true);
    } else {
      setHasChoiceCalendar(false);
    }
  }, [filters]);
  return hasChekedCalendar;
};

export const useHasChekedGuests = (filters: Filters, adultChecked?: boolean) => {
  const [hasChekedGuests, setHasChekedGuests] = useState(false);
  useEffect(() => {
    if (
      (filters.guests.adults + filters.guests.children + filters.guests.babies >= 1 && filters.guestsSelected) ||
      (filters.guests.adults === 1 && adultChecked)
    ) {
      setHasChekedGuests(true);
    } else {
      setHasChekedGuests(false);
    }
  }, [filters]);
  return hasChekedGuests;
};

export const useHasCheckedRooms = (filters: Filters) => {
  const [hasCheckedRooms, setHasCheckedRooms] = useState(false);
  useEffect(() => {
    if (Object.values(filters.rooms).some((room) => room)) {
      setHasCheckedRooms(true);
    } else {
      setHasCheckedRooms(false);
    }
  }, [filters]);
  return hasCheckedRooms;
};

export const useHasCheckedRoomsExtraOptions = (filters: Filters) => {
  const [hasCheckedRoomsExtraOptions, setHasCheckedRoomsExtraOptions] = useState(false);
  useEffect(() => {
    if (Object.values(filters.roomsExtraOptions).some((option) => option)) {
      setHasCheckedRoomsExtraOptions(true);
    } else {
      setHasCheckedRoomsExtraOptions(false);
    }
  }, [filters]);
  return hasCheckedRoomsExtraOptions;
};

export const useHasCheckedBuildingTypes = (filters: Filters) => {
  const [hasCheckedBuildingTypes, setHasCheckedBuildingTypes] = useState(false);
  useEffect(() => {
    if (Object.values(filters.buildingTypes).some((type) => type)) {
      setHasCheckedBuildingTypes(true);
    } else {
      setHasCheckedBuildingTypes(false);
    }
  }, [filters]);
  return hasCheckedBuildingTypes;
};

export const useHasCheckedMinBeds = (filters: Filters) => {
  const [hasCheckedSleepingPlaces, setHasCheckedSleepingPlaces] = useState(false);
  useEffect(() => {
    if (filters.minBeds > 1) {
      setHasCheckedSleepingPlaces(true);
    } else {
      setHasCheckedSleepingPlaces(false);
    }
  }, [filters]);
  return hasCheckedSleepingPlaces;
};

export const useHasCheckedPrice = (filters: Filters, currency: Currency) => {
  const [hasCheckedPrice, setHasCheckedPrice] = useState(false);
  useEffect(() => {
    if (filters.prices.filtered.min !== currency.minPrice || filters.prices.filtered.max !== currency.maxPrice) {
      setHasCheckedPrice(true);
    } else {
      setHasCheckedPrice(false);
    }
  }, [filters, currency]);
  return hasCheckedPrice;
};

export const useHasCheckedFeatures = (filters: Filters) => {
  const [hasCheckedFeatures, setHasCheckedFeatures] = useState(false);
  useEffect(() => {
    if (Object.values(filters.features).includes(true)) {
      setHasCheckedFeatures(true);
    } else {
      setHasCheckedFeatures(false);
    }
  }, [filters]);
  return hasCheckedFeatures;
};

export const useHasCheckedMap = (filters: Filters) => {
  const [hasCheckedMap, setHasCheckedMap] = useState(false);
  useEffect(() => {
    if (Object.values(filters.map).some((option) => option)) {
      setHasCheckedMap(true);
    } else {
      setHasCheckedMap(false);
    }
  }, [filters]);
  return hasCheckedMap;
};

export interface CheckedFilters {
  hasChekedCalendar: boolean;
  hasChekedGuests: boolean;
  hasCheckedRooms: boolean;
  hasCheckedRoomsExtraOptions: boolean;
  hasCheckedBuildingTypes: boolean;
  hasCheckedSleepingPlaces: boolean;
  hasCheckedPrice: boolean;
  hasCheckedFeatures: boolean;
  hasCheckedMap: boolean;
  filtersApplied: boolean;
  filterWithoutMapApplied: boolean;
}

export const useHasChekedFilters = (filters: Filters, currency: Currency, adultChecked?: boolean): CheckedFilters => {
  const hasChekedCalendar = useHasChekedCalendar(filters);
  const hasChekedGuests = useHasChekedGuests(filters, adultChecked);
  const hasCheckedRooms = useHasCheckedRooms(filters);
  const hasCheckedRoomsExtraOptions = useHasCheckedRoomsExtraOptions(filters);
  const hasCheckedBuildingTypes = useHasCheckedBuildingTypes(filters);
  const hasCheckedSleepingPlaces = useHasCheckedMinBeds(filters);
  const hasCheckedPrice = useHasCheckedPrice(filters, currency);
  const hasCheckedFeatures = useHasCheckedFeatures(filters);
  const hasCheckedMap = useHasCheckedMap(filters);

  const filterWithoutMapApplied =
    hasChekedCalendar ||
    hasChekedGuests ||
    hasCheckedRooms ||
    hasCheckedRoomsExtraOptions ||
    hasCheckedBuildingTypes ||
    hasCheckedSleepingPlaces ||
    hasCheckedPrice ||
    hasCheckedFeatures;

  const filtersApplied = filterWithoutMapApplied || hasCheckedMap;

  return {
    hasChekedCalendar,
    hasChekedGuests,
    hasCheckedRooms,
    hasCheckedRoomsExtraOptions,
    hasCheckedBuildingTypes,
    hasCheckedSleepingPlaces,
    hasCheckedPrice,
    hasCheckedFeatures,
    hasCheckedMap,
    filtersApplied,
    filterWithoutMapApplied
  };
};
