import { HeaderBackLink, HeaderBackLinkProps } from 'components/layout/header-back-link/header-back-link';
import { SearchBar } from 'components/search-bar/search-bar';

interface HeaderContentProps {
  backLink?: Optional<HeaderBackLinkProps>;
  hasSearchBar?: boolean;
  hasMobileSearchBar?: boolean;
  mainHost: string;
}

export const HeaderContent = (props: HeaderContentProps) => {
  return (
    <>
      {props.backLink ? <HeaderBackLink {...props.backLink} /> : ''}
      {props.hasSearchBar || props.hasMobileSearchBar ? <SearchBar mainHost={props.mainHost} /> : ''}
    </>
  );
};
