import styles from './building-types.module.css';

import CottageIcon from './icons/cottage.svg';
import FlatIcon from './icons/flat.svg';
import RoomIcon from './icons/room.svg';

import classNames from 'classnames';

import { BuildingTypesFilters } from 'core/entities/filters';
import { getMulticheckboxClasses } from 'core/utils/get-multicheckbox-classes';

import { Checkbox, OnChangeCheckbox } from 'components/common/checkbox/checkbox';

interface BuildingTypesFilterProps {
  className?: string;
  buildingTypes: BuildingTypesFilters;
  onChange: OnChangeCheckbox;
}

export const BuildingTypesFilter = (props: BuildingTypesFilterProps) => {
  const checkboxes = [!!props.buildingTypes.flat, !!props.buildingTypes.cottage, !!props.buildingTypes.room];
  const classes = {
    default: styles.item,
    hasCheckOnLeft: styles.hasCheckOnLeft,
    hasCheckOnRight: styles.hasCheckOnRight
  };

  return (
    <div className={classNames(styles.root, props.className)}>
      <div className={styles.title}>Тип жилья</div>
      <div className={styles.selector}>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 0)}
          variant='button'
          id='flat'
          value='flat'
          checked={props.buildingTypes.flat}
          onToggle={props.onChange}
        >
          <>
            <FlatIcon className={styles.itemIcon} />
            <div className={styles.itemTitle}>квартира</div>
          </>
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 1)}
          variant='button'
          id='cottage'
          value='cottage'
          checked={props.buildingTypes.cottage}
          onToggle={props.onChange}
        >
          <>
            <CottageIcon className={styles.itemIcon} />
            <div className={styles.itemTitle}>дом</div>
          </>
        </Checkbox>
        <Checkbox
          className={getMulticheckboxClasses(checkboxes, classes, 2)}
          variant='button'
          id='room'
          value='room'
          checked={props.buildingTypes.room}
          onToggle={props.onChange}
        >
          <>
            <RoomIcon className={styles.itemIcon} />
            <div className={styles.itemTitle}>комната</div>
          </>
        </Checkbox>
      </div>
    </div>
  );
};
