import { useEffect } from 'react';

import { KeyCodes } from 'core/utils/keys';

export const useOnClickEscape = (handler: () => void) => {
  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (evt.key === KeyCodes.ESC) {
        handler();
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);
};
