// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT, FEATURES_MODAL_TITLE_TEXT } from 'core/constants/filters';
import { Filters, FiltersLoadingType } from 'core/entities/filters';

import { useHasCheckedFeatures } from 'hooks/filters';

import { CheckboxEvent } from 'components/common/checkbox/checkbox';
import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { Modal, useModal } from 'components/common/modal/modal';
import FiltersIcon from 'components/search/controls/icons/moar.svg';
import { FeaturesFilter } from 'components/search/filters/features/features';

interface FeaturesControlProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  isMobileMode: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const FeaturesControl = ({ filters, setFilters, isMobileMode, isLoading, setLoading }: FeaturesControlProps) => {
  const { opened, onOpen, onClose } = useModal();
  const [features, setFeatures] = useState(filters.features);
  const hasCheckedFeatures = useHasCheckedFeatures(filters);

  useEffect(() => {
    // sync filters
    setFeatures(filters.features);
  }, [filters]);

  useEffect(() => {
    if (isMobileMode) {
      onClose();
    }
  }, [isMobileMode]);

  const handleChange = useCallback(
    (event: CheckboxEvent) => {
      setFeatures((prevFeatures) => ({
        ...prevFeatures,
        [event.target.name]: event.target.checked
      }));
    },
    [features]
  );

  const handleApply = useCallback(() => {
    if (isEqual(features, filters.features)) {
      onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }
    const updatedFilters: Filters = {
      ...filters,
      features
    };
    if (setLoading) {
      setLoading((prev) => ({ ...prev, features: true }));
    }
    setFilters(updatedFilters);
    onClose();
  }, [filters, features]);

  const handleClose = () => {
    onClose();
    setFeatures(filters.features);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownButton
        className={styles.btn}
        text={`Ещё${hasCheckedFeatures ? '...' : ''}`}
        icon={<FiltersIcon className={styles.icon} />}
        size='md'
        isActive={opened}
        onClick={onOpen}
        isLoading={isLoading ? isLoading.features : false}
      />
      <Modal
        footerClassName={styles.modalFooter}
        title={FEATURES_MODAL_TITLE_TEXT}
        opened={opened}
        onClose={handleClose}
        onApply={handleApply}
        hasFooter
        btnText={APPLY_FILTER_BUTTON_TEXT}
      >
        <FeaturesFilter variant='buttons' features={features} onChange={handleChange} />
      </Modal>
    </div>
  );
};
