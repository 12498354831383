import styles from './header.module.css';

import classNames from 'classnames';
import { ReactElement, MutableRefObject } from 'react';

import { Actions } from 'components/layout/header/actions/actions';
import { Logo } from 'components/layout/header/logo/logo';

interface HeaderProps {
  children?: ReactElement;
  className?: string;
  noHeader?: boolean;
  isCentral?: boolean;
  isTransparentHead?: boolean;
  isApp?: boolean;
  hasMobileSearchBar?: boolean;
  headerRef?: MutableRefObject<Optional<HTMLDivElement>>;
}

export const Header = (props: HeaderProps) => {
  const { className, children, noHeader = false, isTransparentHead, hasMobileSearchBar } = props;

  if (noHeader || props.isApp) {
    return null;
  }

  return (
    <header
      className={classNames(styles.root, className, {
        [styles.central]: isTransparentHead,
        [styles.mobileSearch]: hasMobileSearchBar
      })}
      ref={props.headerRef}
    >
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <Logo variant={isTransparentHead ? 'white' : 'blue'} />
        </div>
        <div className={classNames(styles.content, { [styles.mobileSearch]: hasMobileSearchBar })}>{children}</div>
        <div className={styles.right}>
          <Actions isCentral={isTransparentHead} />
        </div>
      </div>
    </header>
  );
};
