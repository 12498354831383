import styles from './placeholder.module.css';

import classNames from 'classnames';

import { City } from 'core/entities/geo';

interface PlaceholderProps {
  className?: string;
  city?: Optional<City>;
  onClick: () => void;
}

export const Placeholder = (props: PlaceholderProps) => {
  if (!props.city) {
    return null;
  }

  return (
    <div className={classNames(styles.root, props.className)} onClick={props.onClick}>
      <span className={styles.placeName}>{props.city.name}</span>
      <span>, {props.city.hasMetro ? 'метро' : 'район'}, улица, место</span>
    </div>
  );
};
