import styles from './search-filters.module.css';

import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';

import { BREAKPOINT } from 'core/constants/breakpoints';
import { DEFAULT_CURRENCY } from 'core/constants/default-values';

import { CommonContext } from 'contexts/common';
import { FiltersContext } from 'contexts/filters/filters';

import { useWindowDimensions } from 'hooks/use-window-dimensions';

import { BuildingTypesControl } from 'components/search/controls/building-types/building-types';
import { CalendarControl } from 'components/search/controls/calendar/calendar';
import { FeaturesControl } from 'components/search/controls/features/features';
import { FiltersControl } from 'components/search/controls/filters/filters';
import { GuestsControl } from 'components/search/controls/guests/guests';
import { GuestsModalControl } from 'components/search/controls/guests/guests-modal';
import { PricesControl } from 'components/search/controls/prices/prices';

interface SearchProps {
  className?: string;
  mainHost: string;
}

const useFiltersMode = () => {
  const dimensions = useWindowDimensions();
  const [isMobileMode, setIsMobileMode] = useState(true);

  useEffect(() => {
    if (dimensions.width < BREAKPOINT.medium) {
      setIsMobileMode(true);
    } else {
      setIsMobileMode(false);
    }
  }, [dimensions.width]);

  return isMobileMode;
};

export const SearchFilters = ({ className, mainHost }: SearchProps) => {
  const { context } = useContext(CommonContext);
  const { filters, isLandingWithPrice, setFilters, checkAdult, adultChecked, isLoading, setLoading } =
    useContext(FiltersContext);
  const isMobileMode = useFiltersMode();
  const controlProps = {
    filters,
    setFilters,
    adultChecked,
    isLoading,
    setLoading
  };

  const defaultCurrencies = {
    current: DEFAULT_CURRENCY,
    primary: DEFAULT_CURRENCY
  };

  return (
    <div className={classNames(styles.root, className)}>
      <CalendarControl {...controlProps} mainHost={mainHost} />
      <div className={styles.desktopButtons}>
        <GuestsControl {...controlProps} mainHost={mainHost} checkAdult={checkAdult} />
        <BuildingTypesControl
          {...controlProps}
          currency={context ? context.currencies.current : defaultCurrencies.current}
        />
        <PricesControl
          {...controlProps}
          isLandingWithPrice={isLandingWithPrice}
          currencies={context ? context.currencies : defaultCurrencies}
        />
        <FeaturesControl {...controlProps} isMobileMode={isMobileMode} />
      </div>
      <div className={styles.mobileButtons}>
        <GuestsModalControl {...controlProps} mainHost={mainHost} isMobileMode={isMobileMode} checkAdult={checkAdult} />
        <FiltersControl
          {...controlProps}
          isLandingWithPrice={isLandingWithPrice}
          currencies={context ? context.currencies : defaultCurrencies}
          isMobileMode={isMobileMode}
        />
      </div>
    </div>
  );
};
