import styles from './month.module.css';

import classNames from 'classnames';
import { useLayoutEffect, useMemo, useRef } from 'react';

import { pastWeeks, weeksRange } from 'core/utils/calendar';

import { Ruler } from 'components/calendar/ruler/ruler';
import { Week } from 'components/calendar/week/week';

const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

interface MonthProps {
  first?: boolean;
  start: Date;
  containsError?: boolean;
  current?: boolean;
}

export const Month = (props: MonthProps) => {
  const weeks = useMemo(() => weeksRange(props.start), [props.start]);
  const past = useMemo(() => pastWeeks(props.start), [props.start]);
  const yearClass = classNames(styles.yearTitle, {
    [styles.optionalYear]: !props.first && props.start.getMonth() > 0
  });

  const monthRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (props.current && monthRef.current) {
      monthRef.current.scrollIntoView({ inline: 'start', block: 'start' });
    }
  }, [props.current]);

  return (
    <div className={styles.root} ref={monthRef}>
      <div className={classNames(styles.title, styles.desktopTitle)}>
        {monthNames[props.start.getMonth()]}&nbsp;
        <span className={yearClass}>{props.start.getFullYear()}</span>
      </div>
      <Ruler className={styles.ruler} />
      <div className={styles.weeks}>
        <div className={classNames(styles.title, styles.mobileTitle)}>
          {monthNames[props.start.getMonth()]}&nbsp;
          <span className={yearClass}>{props.start.getFullYear()}</span>
        </div>
        {past.map((dates, idx) => (
          <Week
            dates={dates}
            key={`past_${dates[0].valueOf()}`}
            past={true}
            first={!idx}
            containsError={props.containsError}
          />
        ))}
        {weeks.map((dates, idx) => (
          <Week dates={dates} key={`week_${dates[0].valueOf()}`} first={!idx} containsError={props.containsError} />
        ))}
      </div>
    </div>
  );
};
