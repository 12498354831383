import styles from './ruler.module.css';

import classNames from 'classnames';

interface RulerProps {
  className?: string;
}

export const Ruler = ({ className }: RulerProps) => {
  const weekdays = ['пн', 'вт', 'ср', 'чт', 'пт'];
  const weekends = ['сб', 'вс'];

  return (
    <div className={classNames(styles.root, className)}>
      {weekdays.map((day) => (
        <span className={styles.rulerDay} key={day}>
          {day}
        </span>
      ))}
      {weekends.map((day) => (
        <span className={classNames(styles.rulerDay, styles.weekend)} key={day}>
          {day}
        </span>
      ))}
    </div>
  );
};
