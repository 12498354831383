import { isSameDay } from 'date-fns';
import React from 'react';

import { BookingInterval } from 'core/entities/booking';

interface WidgetState extends Partial<BookingInterval> {
  lastHover?: Date;
  useOnComplete?: Optional<boolean>;
}

interface WidgetContextState extends Partial<BookingInterval> {
  hover?: Optional<BookingInterval>;
  selection?: Optional<BookingInterval>;
  onClick?: (date: Date) => void;
  onHover?: (date: Date) => void;
  onComplete?: (date: Date) => void;
}

export const WidgetContext: React.Context<WidgetContextState> = React.createContext({});

function selection(state: Partial<BookingInterval>): Optional<BookingInterval> {
  if (state.arrival && state.departure) {
    return { arrival: state.arrival, departure: state.departure };
  }
  return null;
}

function hoverRange(state: WidgetState): Optional<BookingInterval> {
  if (state.arrival && !state.departure) {
    // минимум 1 день
    const range = { arrival: state.arrival, departure: state.arrival };
    if (state.lastHover && state.lastHover > range.departure) {
      range.departure = state.lastHover;
    }
    return range;
  }
  return null;
}

export const waitingForArrival = (state: Partial<BookingInterval>) =>
  !state.arrival || !state.arrival === !state.departure;

enum Action {
  DAY_CLICKED,
  DAY_HOVERED,
  RANGE_COMPLETED
}

// eslint-disable-next-line complexity
function selectionReducer(state: WidgetState, action: { type: Action; payload: Date }): WidgetState {
  switch (action.type) {
    case Action.DAY_HOVERED:
      if (state.arrival && action.payload < state.arrival) {
        return { ...state, lastHover: state.arrival };
      }
      return { ...state, lastHover: action.payload };
    case Action.DAY_CLICKED:
      if (waitingForArrival(state)) {
        return { arrival: action.payload, lastHover: action.payload };
      }
      if (isSameDay(action.payload, state.arrival as Date)) {
        return {};
      }
      if (state.arrival && action.payload < state.arrival) {
        return { arrival: action.payload };
      }

      return { arrival: state.arrival, departure: action.payload };
    case Action.RANGE_COMPLETED:
      if (!state.useOnComplete) {
        return state;
      }
      if (state.arrival && !state.departure) {
        return { arrival: state.arrival, departure: action.payload };
      }
      return state;
    default:
      return state;
  }
}

interface CalendarProviderProps {
  initial?: BookingInterval;
  children?: React.ReactChild | React.ReactChild[];
  onSelectRange?: (range: Optional<BookingInterval>) => void;
  useOnComplete?: Optional<boolean>;
}

export const CalendarProvider = ({ initial, children, onSelectRange, useOnComplete }: CalendarProviderProps) => {
  const [state, dispatch] = React.useReducer(selectionReducer, initial || { useOnComplete });
  const value: WidgetContextState = {
    arrival: state.arrival,
    departure: state.departure,
    selection: selection(state),
    hover: React.useMemo(() => hoverRange(state), [state.arrival, state.departure, state.lastHover]),
    onHover: React.useCallback((date: Date) => dispatch({ type: Action.DAY_HOVERED, payload: date }), []),
    onClick: React.useCallback((date: Date) => dispatch({ type: Action.DAY_CLICKED, payload: date }), []),
    onComplete: React.useCallback((date: Date) => dispatch({ type: Action.RANGE_COMPLETED, payload: date }), [])
  };
  const enableEffect = React.useRef(false);

  React.useEffect(() => {
    if (onSelectRange && enableEffect.current) {
      onSelectRange(selection(state));
    }
    enableEffect.current = true;
  }, [state.arrival, state.departure, onSelectRange]);

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};
