import { MutableRefObject, RefObject, useEffect, useRef } from 'react';

import { BREAKPOINT } from 'core/constants/breakpoints';

import { BrowserFamily, useBrowser } from 'hooks/use-browser';
import { useWindowDimensions } from 'hooks/use-window-dimensions';

interface ScrollConfig {
  minWheelStep: number;
  maxWheelStep: number;
  wheelSpeed: number;
}

// Попытка приблизительно уравнять скорость скролла в разных браузерах
const DEFAULT_SCROLL_CONFIG: ScrollConfig = {
  minWheelStep: 32,
  maxWheelStep: 64,
  wheelSpeed: 16
};

const SAFARI_SCROLL_CONFIG: ScrollConfig = {
  minWheelStep: 1,
  maxWheelStep: 1000,
  wheelSpeed: 1
};

class ScrollEmulator {
  protected start = 0;
  protected scrolling = false;
  protected readonly element: HTMLElement;
  protected readonly config: ScrollConfig;

  constructor(element: HTMLElement, config: ScrollConfig) {
    this.element = element;
    this.config = config;
  }

  public activate(start: number) {
    this.start = start;
    this.scrolling = true;
  }

  public deactivate = () => {
    this.scrolling = false;
  };

  public nextScroll(finish: number) {
    return this.start - finish;
  }

  public wheelDelta(original: number): number {
    return (
      Math.sign(original) *
      Math.max(
        Math.min(Math.abs(original) * this.config.wheelSpeed, this.config.maxWheelStep),
        this.config.minWheelStep
      )
    );
  }

  public install() {
    this.element.addEventListener('mouseup', this.deactivate);
    this.element.addEventListener('mouseleave', this.deactivate);
  }

  public uninstall() {
    this.element.removeEventListener('mouseup', this.deactivate);
    this.element.removeEventListener('mouseleave', this.deactivate);
  }
}

class HorizontalScrollEmulator extends ScrollEmulator {
  public handleMove = (event: MouseEvent) => {
    event.preventDefault();
    if (this.scrolling) {
      this.element.scrollLeft = this.nextScroll(event.pageX - this.element.offsetLeft);
    }
  };

  public handleActivation = (event: MouseEvent) => {
    this.activate(event.pageX - this.element.offsetLeft + this.element.scrollLeft);
  };

  public handleWheel = (event: WheelEvent) => {
    event.preventDefault();
    const delta = event.deltaX !== 0 ? event.deltaX : event.deltaY;
    this.element.scrollLeft = this.element.scrollLeft + this.wheelDelta(delta);
    return;
  };

  public install() {
    super.install();
    this.element.addEventListener('mousedown', this.handleActivation);
    this.element.addEventListener('wheel', this.handleWheel);
    this.element.addEventListener('mousemove', this.handleMove);
  }

  public uninstall() {
    super.uninstall();
    this.element.removeEventListener('mousedown', this.handleActivation);
    this.element.removeEventListener('wheel', this.handleWheel);
    this.element.removeEventListener('mousemove', this.handleMove);
  }
}

export function useScroll<T extends HTMLElement>(
  shouldScroll: boolean,
  Emulator: typeof ScrollEmulator,
  config: ScrollConfig
): MutableRefObject<Optional<T>> {
  const ref = useRef<T>(null);

  useEffect(() => {
    let emulator: ScrollEmulator;
    if (shouldScroll && ref.current) {
      emulator = new Emulator(ref.current, config);
      emulator.install();
    }
    return () => {
      if (shouldScroll && ref.current) {
        emulator.uninstall();
      }
    };
  }, [shouldScroll, ref.current, Emulator]);

  return ref;
}

export function useDesktopHorizontalScroll<T extends HTMLElement>(config?: ScrollConfig): RefObject<T> {
  const dimensions = useWindowDimensions();
  const shouldScroll = dimensions.width > BREAKPOINT.small && dimensions.height > 576;
  const browser = useBrowser();
  return useScroll<T>(
    shouldScroll,
    HorizontalScrollEmulator,
    browser === BrowserFamily.safari ? SAFARI_SCROLL_CONFIG : config || DEFAULT_SCROLL_CONFIG
  );
}
