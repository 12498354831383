import styles from './content.module.css';

import React, { useState, useEffect } from 'react';

import { CancelButton } from 'components/search-bar/cancel-button/cancel-button';

interface ContentProps {
  children?: React.ReactChild;
  isActive: boolean;
  isTransitionLoading: boolean;
  isSearchLoading: boolean;
  onCancel: () => void;
  isCentral?: boolean;
  isMobile: boolean;
}

export const Content = (props: ContentProps) => {
  const { children, isActive, isTransitionLoading, isSearchLoading, onCancel, isCentral, isMobile } = props;
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    if (!isCentral) {
      if (!showBtn && isActive) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    } else if (isMobile && !showBtn && isActive) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [isActive, isCentral, isMobile]);

  return (
    <div className={styles.root}>
      {showBtn && <CancelButton className={styles.cancelButton} onClick={onCancel} />}
      {!isTransitionLoading && !isSearchLoading && !isActive && <div>{children}</div>}
    </div>
  );
};
