import styles from './find-button.module.css';

import React from 'react';

import { Button } from 'components/common/button/button';

interface FindButtonProps {
  show: boolean;
  onClick: () => void;
}

export const FindButton = ({ show, onClick }: FindButtonProps) => {
  if (show) {
    return <Button className={styles.root} onClick={onClick} text='Найти' size='sm' />;
  }
  return null;
};
