// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT, FILTERS_MODAL_TITLE_TEXT } from 'core/constants/filters';
import { Currency } from 'core/entities';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { checkIsObjectChanged } from 'core/utils/filters/checkIsObjectChanged';

import { useHasChekedFilters } from 'hooks/filters';

import { CheckboxEvent } from 'components/common/checkbox/checkbox';
import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { Modal, useModal } from 'components/common/modal/modal';
import FiltersIcon from 'components/search/controls/icons/moar.svg';
import { BuildingTypesFilter } from 'components/search/filters/building-types/building-types';
import { FeaturesFilter } from 'components/search/filters/features/features';
import { PricesFilter } from 'components/search/filters/prices/prices';
import { RoomsFilter } from 'components/search/filters/rooms/rooms';
import { RoomsExtraOptionsFilter } from 'components/search/filters/rooms-extra-options/rooms-extra-options';
import { SleepingPlacesFilter } from 'components/search/filters/sleeping-places/sleeping-places';

interface FiltersControlProps {
  className?: string;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  isLandingWithPrice: boolean;
  currencies: {
    current: Currency;
    primary: Currency;
  };
  isMobileMode: boolean;
  adultChecked: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const FiltersControl = ({
  className,
  filters,
  setFilters,
  isLandingWithPrice,
  currencies,
  isMobileMode,
  adultChecked,
  isLoading,
  setLoading
}: FiltersControlProps) => {
  const { opened, onOpen, onClose } = useModal();
  const [hasFilters, setHasFilters] = useState(false);
  const [buildingTypes, setBuildingTypes] = useState(filters.buildingTypes);
  const [rooms, setRooms] = useState(filters.rooms);
  const [roomsExtraOptions, setRoomsExtraOptions] = useState(filters.roomsExtraOptions);
  const [minBeds, setMinBeds] = useState(filters.minBeds);
  const [price, setPrice] = useState([filters.prices.filtered.min, filters.prices.filtered.max]);
  const [features, setFeatures] = useState(filters.features);
  const {
    hasChekedCalendar,
    hasCheckedBuildingTypes,
    hasCheckedRooms,
    hasCheckedRoomsExtraOptions,
    hasCheckedFeatures,
    hasCheckedSleepingPlaces,
    hasCheckedPrice
  } = useHasChekedFilters(filters, currencies.current, adultChecked);

  useEffect(() => {
    // sync filters
    setBuildingTypes(filters.buildingTypes);
    setRooms(filters.rooms);
    setRoomsExtraOptions(filters.roomsExtraOptions);
    setMinBeds(filters.minBeds);
    setPrice([filters.prices.filtered.min, filters.prices.filtered.max]);
    setFeatures(filters.features);

    if (
      hasCheckedBuildingTypes ||
      hasCheckedRooms ||
      hasCheckedRoomsExtraOptions ||
      hasCheckedFeatures ||
      hasCheckedSleepingPlaces ||
      hasCheckedPrice
    ) {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }
  }, [
    filters,
    hasCheckedBuildingTypes,
    hasCheckedRooms,
    hasCheckedRoomsExtraOptions,
    hasCheckedFeatures,
    hasCheckedSleepingPlaces,
    hasCheckedPrice
  ]);

  // эффект установки фильтров в зависимости от других фильтров
  useEffect(() => {
    if (!buildingTypes.room) {
      setRoomsExtraOptions({
        bathroom: false,
        kitchen: false
      });
    }
    if (buildingTypes.room && !buildingTypes.flat && !buildingTypes.cottage) {
      setRooms({
        one: false,
        two: false,
        three: false,
        four: false,
        five: false
      });
    }
  }, [buildingTypes]);

  useEffect(() => {
    if (!isMobileMode) {
      onClose();
    }
  }, [isMobileMode]);

  const handleBuildingTypesChange = useCallback(
    (event: CheckboxEvent) => {
      setBuildingTypes({
        ...buildingTypes,
        [event.target.name]: event.target.checked
      });
    },
    [filters, buildingTypes]
  );

  const handleRoomsChange = useCallback(
    (event: CheckboxEvent) => {
      setRooms({
        ...rooms,
        [event.target.name]: event.target.checked
      });
    },
    [filters, rooms]
  );

  const handleRoomsExtraOptionsChange = useCallback(
    (event: CheckboxEvent) => {
      setRoomsExtraOptions({
        ...roomsExtraOptions,
        [event.target.name]: event.target.checked
      });
    },
    [filters, roomsExtraOptions]
  );

  const handleMinBedsChange = useCallback(
    (value: number) => {
      setMinBeds(value);
    },
    [filters]
  );

  const handlePricesChange = useCallback((value: Array<number>) => {
    setPrice([value[0], value[1]]);
  }, []);

  const handleFeaturesChange = useCallback(
    (event: CheckboxEvent) => {
      setFeatures((prevFeatures) => ({
        ...prevFeatures,
        [event.target.name]: event.target.checked
      }));
    },
    [filters]
  );

  const handleApply = useCallback(() => {
    const isBuildingTypesChanged = checkIsObjectChanged(buildingTypes, filters.buildingTypes);
    const isRoomsChanged = checkIsObjectChanged(rooms, filters.rooms);

    const updatedFiltersWithoutTypesAndRooms: Filters = {
      ...filters,
      roomsExtraOptions,
      minBeds,
      prices: {
        ...filters.prices,
        filtered: {
          min: price[0],
          max: price[1]
        }
      },
      features
    };

    if (!isBuildingTypesChanged && !isRoomsChanged && isEqual(updatedFiltersWithoutTypesAndRooms, filters)) {
      onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }

    const updatedFilters: Filters = {
      ...filters,
      buildingTypes,
      rooms,
      roomsExtraOptions,
      minBeds,
      prices: {
        ...filters.prices,
        filtered: {
          min: price[0],
          max: price[1]
        }
      },
      features
    };

    if (setLoading) {
      setLoading((prev) => ({ ...prev, mobileFilters: true }));
    }
    setFilters(updatedFilters);
    onClose();
  }, [filters, buildingTypes, rooms, roomsExtraOptions, minBeds, price, features]);

  const handleClick = useCallback(() => {
    // обход конфликта при рендере слайдера для цен
    setTimeout(() => onOpen(), 0);
  }, []);

  const handleClose = () => {
    onClose();
    setHasFilters(false);
    setBuildingTypes(filters.buildingTypes);
    setRooms(filters.rooms);
    setRoomsExtraOptions(filters.roomsExtraOptions);
    setMinBeds(filters.minBeds);
    setPrice([filters.prices.filtered.min, filters.prices.filtered.max]);
    setFeatures(filters.features);
  };

  const showRoomsFilter = !(!(buildingTypes.flat || buildingTypes.cottage) && buildingTypes.room);

  return (
    <div className={styles.wrapper}>
      <DropdownButton
        className={classNames(styles.btn, className)}
        text={hasFilters ? '•' : 'Ещё'}
        compact={hasChekedCalendar}
        compactText={hasFilters ? '•' : ''}
        icon={<FiltersIcon className={styles.icon} />}
        size='md'
        isActive={opened}
        onClick={handleClick}
        isLoading={isLoading ? isLoading.mobileFilters : false}
      />
      <Modal
        footerClassName={styles.modalFooter}
        title={FILTERS_MODAL_TITLE_TEXT}
        opened={opened}
        onClose={handleClose}
        onApply={handleApply}
        hasFooter
        btnText={APPLY_FILTER_BUTTON_TEXT}
      >
        <>
          <BuildingTypesFilter buildingTypes={buildingTypes} onChange={handleBuildingTypesChange} />
          {showRoomsFilter && <RoomsFilter rooms={rooms} onChange={handleRoomsChange} />}
          <SleepingPlacesFilter minBeds={minBeds} onChangeMinBeds={handleMinBedsChange} />
          {buildingTypes.room && (
            <RoomsExtraOptionsFilter roomsExtraOptions={roomsExtraOptions} onChange={handleRoomsExtraOptionsChange} />
          )}
          <PricesFilter
            className={styles.filter}
            value={price}
            onChange={handlePricesChange}
            currencies={currencies}
            filters={filters}
            isLandingWithPrice={isLandingWithPrice}
          />
          <FeaturesFilter onChange={handleFeaturesChange} features={features} />
        </>
      </Modal>
    </div>
  );
};
