export const ROOMS_EXTRA_OPTIONS_TEXT = {
  KITCHEN_OPTIONS: {
    PRIVATE: 'Личная кухня',
    SHARED: 'Общая кухня',
    DISABLED: 'Нет кухни'
  },
  BATHROOM_OPTIONS: {
    PRIVATE: 'Личная ванная комната',
    SHARED: 'Общая ванная комната',
    DISABLED: 'Нет ванной комнаты'
  }
};

export const APPLY_FILTER_BUTTON_TEXT = 'Применить';
export const DEFAULT_BUTTON_TEXT = 'Цена';
export const COMPACT_BUTTON_TEXT = 'Цена...';

export const CALENDAR_MODAL_TITLE_TEXT = 'Даты заезда и\xa0выезда';
export const CALENDAR_MODAL_SKIP_BUTTON_TEXT = 'Указать позже';
export const CALENDAR_MODAL_CONTINUE_BUTTON_TEXT = 'Продолжить';
export const CALENDAR_ONLY_FOR_DEPARTURES_TEXT = 'Дата доступна только на\xa0выезд';
export const CALENDAR_BOOKED_DAY_TEXT = 'Дата занята';
export const CALENDAR_BOOKED_TEXT = 'В\xa0этом периоде есть занятые даты';
export const CALENDAR_SHORT_DURATION_BEFORE_BLOCK_DAY_TEXT = (durationText: string) =>
  `Минимум ${durationText}, есть занятые даты`;
export const CALENDAR_SHORT_DURATION_TEXT = (durationText: string) => `Минимум ${durationText}`;

export const FEATURES_MODAL_TITLE_TEXT = 'Свойства жилья';
export const GUESTS_MODAL_TITLE_TEXT = 'Состав гостей';
export const FILTERS_MODAL_TITLE_TEXT = 'Подбор';
