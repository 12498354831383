import { addDays, format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { dateFormat } from 'core/constants/date-formats';
import { DatesFilterMacros } from 'core/entities/filters';

export function formatDateMacrosToISO(dateMacros: DatesFilterMacros): Optional<string> {
  let formattedDate = null;
  if (dateMacros === DatesFilterMacros.today) {
    formattedDate = format(Date.now(), dateFormat.iso, { locale: ru });
  }
  if (dateMacros === DatesFilterMacros.tomorrow) {
    formattedDate = format(addDays(Date.now(), 1), dateFormat.iso, { locale: ru });
  }
  if (dateMacros === DatesFilterMacros.overmorrow || dateMacros === DatesFilterMacros.dayAfterTomorrow) {
    formattedDate = format(addDays(Date.now(), 2), dateFormat.iso, { locale: ru });
  }

  return formattedDate;
}
