import {
  useState,
  useEffect,
  RefObject,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useContext,
  useRef,
  useCallback
} from 'react';

import { MAP_SETTINGS } from 'core/constants/map-settings';
import { SUGGESTS_DESKTOP_LIMIT } from 'core/constants/suggests';
import { Filters } from 'core/entities/filters';
import { SuggestListItem, SuggestType } from 'core/entities/suggest';
import { GeocoderService } from 'core/services/context/search/geocoder';
import { SuggestsService } from 'core/services/suggests';
import { getQueryParamsFromUrl } from 'core/utils/filters/search-filter';
import { KeyCodes } from 'core/utils/keys';

import { FiltersContext } from 'contexts/filters/filters';
import { SearchBarContext, SearchBarMode } from 'contexts/search/search-bar';

import { useFlatsRoutes } from 'hooks/router/use-flats-routes';
import { useLockBodyScroll } from 'hooks/scroll/use-lock-body-scroll';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { useBreakpoints } from 'hooks/use-window-dimensions';

export interface SearchBar {
  currentSuggestIndex: number;
  autofocus: boolean;
  isTransitionLoading: boolean;
  isSearchLoading: boolean;
  searchBarRef: RefObject<HTMLDivElement>;
  inputRef: RefObject<HTMLInputElement> | string;
  handleChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClickPlaceholder: () => void;
  handleFocus: () => void;
  handleBlur: () => void;
  handleReset: () => void;
  handleCancel: () => void;
  handleKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleMouseEnterSuggest: (index: number) => void;
  handleSubmit: () => void;
  handleClickSuggest: (suggest: SuggestListItem) => void;
  handlePressEnter: () => void;
}

export const useSearchBar = (mainHost: string, isCentral?: boolean, onSubmit?: (url: string) => void): SearchBar => {
  const searchBarContext = useContext(SearchBarContext);
  const { filters, setFilters } = useContext(FiltersContext);

  const [allowedClickOutside, setAllowedClickOutside] = useState(false);
  const [autofocus, setAutofocus] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isTransitionLoading, setIsTransitionLoading] = useState(false);
  const [currentSuggestIndex, setCurrentSuggestIndex] = useState(-1);

  const searchBarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestsService = useRef(new SuggestsService(mainHost));
  const geocoderService = useRef(new GeocoderService(mainHost));
  const requestTimer = useRef<Optional<NodeJS.Timeout>>(null);

  const { isMobile } = useBreakpoints();
  const { isMapPage, goToFlatsMapPage, goToFlatsListPage } = useFlatsRoutes(mainHost);
  const { locked, setLocked } = useLockBodyScroll(false, false);

  useEffect(() => {
    if (isMobile && searchBarContext.mode === SearchBarMode.ACTIVE && searchBarContext.suggests.length > 0) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'relative';
    }
  }, [searchBarContext.mode, isMobile, searchBarContext.suggests]);

  useEffect(() => {
    if (isCentral && isMobile) {
      // fix scrolling bug at mobile devices, when search input is active at central page
      const rootElement = document.getElementById('__next');
      if (rootElement) {
        if (searchBarContext.mode === SearchBarMode.ACTIVE) {
          document.body.style.position = 'fixed';
          rootElement.style.overflowY = 'hidden';
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 50);
        } else {
          document.body.style.position = 'relative';
          rootElement.style.overflowY = 'auto';
        }
      }
    }
  }, [searchBarContext.mode, isMobile, isCentral]);

  useEffect(() => {
    const handleScroll = () => {
      if (inputRef.current && isMobile && searchBarContext.suggests.length > 0) {
        inputRef.current.blur();
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [searchBarContext.suggests, isMobile]);

  useEffect(() => {
    if (searchBarContext.mode === SearchBarMode.ACTIVE) {
      setAllowedClickOutside(true);
    }
  }, [searchBarContext.mode]);

  const fetchSuggest = useCallback(
    async (value: string) => {
      try {
        setIsSearchLoading(true);
        if (value.length > 2) {
          const { suggests: fetchedSuggests } = await suggestsService.current.fetchSuggests(value);
          if (isMobile && fetchedSuggests.length > 0 && searchBarContext.mode === SearchBarMode.ACTIVE) {
            setLocked(true);
          }
          searchBarContext.setSuggests(fetchedSuggests);
        }
      } catch (err) {
        // setErrorValue(String(err));
      } finally {
        setIsSearchLoading(false);
      }
    },
    [isMobile, suggestsService.current]
  );

  const handleSearch = useCallback(
    (value: string) => {
      if (requestTimer.current) {
        clearTimeout(requestTimer.current);
      }
      if (value.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        requestTimer.current = setTimeout(() => fetchSuggest(value), 500);
      } else {
        searchBarContext.setSuggests([]);
      }
    },
    [fetchSuggest, requestTimer]
  );

  /* eslint max-depth: ["error", 4] */
  const handleSubmit = useCallback(async () => {
    setIsTransitionLoading(true);
    try {
      const suggest = await geocoderService.current.fetchByQuery(searchBarContext.value);

      if (suggest.url) {
        let updatedFilters: Filters;
        const splittedUrl = suggest.url.split('?');
        const { point_lat, point_lng } = getQueryParamsFromUrl(splittedUrl[1]);
        const isPlace = Boolean(suggest.addressParts.district);

        if (isPlace) {
          updatedFilters = {
            ...filters,
            map: {
              lat: Number(point_lat),
              lng: Number(point_lng),
              zoom: MAP_SETTINGS.zoomForPlace,
              minLng: null,
              minLat: null,
              maxLng: null,
              maxLat: null
            },
            pointName: searchBarContext.value
          };
        } else {
          updatedFilters = {
            ...filters,
            map: {}
          };
        }

        if (onSubmit) {
          setFilters(updatedFilters);
          onSubmit(`${splittedUrl[0]}map`);
          return;
        }

        goToFlatsMapPage(updatedFilters, `${splittedUrl[0]}map`);
      }
    } catch (err) {
      // setErrorValue(String(err));
    } finally {
      setIsTransitionLoading(false);

      if (!isCentral) {
        searchBarContext.setMode(SearchBarMode.WITH_VALUE);
      }

      searchBarContext.setIsMapArea();

      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [searchBarContext.value, isMapPage, goToFlatsMapPage, filters]);

  const handleChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      searchBarContext.setValue(value);
      searchBarContext.setOriginalValue(value);
      setCurrentSuggestIndex(-1);
      handleSearch(value);
    },
    [currentSuggestIndex, handleSearch]
  );

  const handleClickPlaceholder = useCallback(() => {
    setAutofocus(true);
    searchBarContext.setMode(SearchBarMode.ACTIVE);
  }, []);

  const handleDeactivate = useCallback(
    (checkedValue: string) => {
      setLocked(false);
      setAllowedClickOutside(false);
      if (searchBarContext.hasCity && checkedValue.length === 0) {
        searchBarContext.setMode(SearchBarMode.CITY);
      } else if (searchBarContext.hasMapArea && checkedValue.length === 0) {
        searchBarContext.setMode(SearchBarMode.MAP_AREA);
      } else if (checkedValue.length > 0) {
        searchBarContext.setMode(SearchBarMode.WITH_VALUE);
        searchBarContext.setValue(checkedValue);
      } else {
        searchBarContext.setMode(SearchBarMode.DEFAULT);
      }
    },
    [searchBarContext.hasCity, searchBarContext.hasMapArea]
  );

  const handleCancel = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.stopPropagation();
      }
      handleDeactivate(searchBarContext.savedValue);
      setIsSearchLoading(false);
      if (searchBarContext.savedValue.length === 0) {
        searchBarContext.setValue('');
        searchBarContext.setSuggests([]);
      }
    },
    [searchBarContext.savedValue, handleDeactivate]
  );

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      if (isMobile && !isCentral) {
        handleCancel();
      }
    }, 0);
  }, [handleCancel, isMobile]);

  const handleClickOutside = useCallback(() => {
    if (isCentral && isMobile) {
      return null;
    } else if ((isMobile && searchBarContext.suggests.length === 0) || !isMobile) {
      handleDeactivate(searchBarContext.value);
    }
    return null;
  }, [isMobile, searchBarContext.value, searchBarContext.suggests, handleDeactivate]);

  const handleReset = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.stopPropagation();
      }
      searchBarContext.setMode(SearchBarMode.ACTIVE);
      searchBarContext.setValue('');
      searchBarContext.setSavedValue('');
      searchBarContext.setSuggests([]);
      setLocked(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef.current]
  );

  const handleFocus = useCallback(() => {
    setAutofocus(false);
    searchBarContext.setMode(SearchBarMode.ACTIVE);
    if (isMobile && searchBarContext.suggests.length > 0) {
      setLocked(true);
    }
  }, [isMobile, searchBarContext.suggests]);

  const handleChangeSuggest = useCallback(
    (index: number) => {
      const suggest = searchBarContext.suggests[index];
      searchBarContext.setValue(suggest ? suggest.name : searchBarContext.originalValue);
      setCurrentSuggestIndex(suggest ? index : -1);
    },
    [searchBarContext.suggests, searchBarContext.originalValue]
  );

  const handlePressEnter = useCallback(async () => {
    const suggest = searchBarContext.suggests[currentSuggestIndex] || searchBarContext.suggests[0];

    let isEqual = false;
    if (suggest) {
      isEqual = suggest.name.toLowerCase() === searchBarContext.value.toLowerCase();
    }

    if (suggest && isEqual) {
      await handleClickSuggest(suggest);
    } else {
      setCurrentSuggestIndex(-1);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [searchBarContext.suggests, currentSuggestIndex, handleSubmit, searchBarContext.value]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;
      const keys = [KeyCodes.UP, KeyCodes.DOWN] as Array<string>;

      if (key === KeyCodes.ENTER) {
        event.preventDefault();
      }
      if (
        searchBarContext.mode === SearchBarMode.ACTIVE &&
        searchBarContext.suggests.length &&
        keys.indexOf(key) !== -1
      ) {
        event.preventDefault();
        let suggestIndex = currentSuggestIndex;
        if (key === KeyCodes.UP) {
          if (suggestIndex <= -1) {
            suggestIndex = SUGGESTS_DESKTOP_LIMIT - 1;
          } else {
            suggestIndex = suggestIndex - 1;
          }
        } else if (key === KeyCodes.DOWN) {
          if (suggestIndex === SUGGESTS_DESKTOP_LIMIT - 1) {
            suggestIndex = 0;
          } else {
            suggestIndex = suggestIndex + 1;
          }
        }
        handleChangeSuggest(suggestIndex);
      }
    },
    [currentSuggestIndex, searchBarContext.suggests, searchBarContext.mode, handleChangeSuggest]
  );

  const handleKeyUp = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;
      const keys = [KeyCodes.UP, KeyCodes.DOWN] as Array<string>;
      if (keys.indexOf(key) !== -1) {
        event.preventDefault();
      } else if (key === KeyCodes.ENTER && searchBarContext.value.length) {
        await handlePressEnter();
      } else if (key === KeyCodes.ESC) {
        setCurrentSuggestIndex(-1);
        handleCancel();
      }
    },

    [handlePressEnter, searchBarContext.value]
  );

  const handleMouseEnterSuggest = useCallback(
    (index: number) => {
      handleChangeSuggest(index);
    },
    [handleChangeSuggest]
  );

  const handleClickSuggest = useCallback(
    async (suggest: SuggestListItem) => {
      setIsTransitionLoading(true);

      let updatedFilters = { ...filters };
      let url = suggest.url;

      if (url.length > 0 && (suggest.type === SuggestType.city || suggest.type === SuggestType.region)) {
        updatedFilters.map = {};

        if (onSubmit) {
          onSubmit(url);
          setIsTransitionLoading(false);
          return;
        }

        goToFlatsMapPage(updatedFilters, url);
        return;
      }

      if (suggest.id.length > 4) {
        try {
          const suggestDetail = await suggestsService.current.fetchSuggest(suggest.id);
          url = suggestDetail.url.split('?')[0];

          updatedFilters = {
            ...updatedFilters,
            map: {
              lng: suggestDetail.point.lng,
              lat: suggestDetail.point.lat,
              zoom: MAP_SETTINGS.zoomForPlace,
              minLng: null,
              minLat: null,
              maxLng: null,
              maxLat: null
            },
            pointName: suggest.name
          };

          if (onSubmit) {
            setFilters(updatedFilters);
            onSubmit(url);
            setIsTransitionLoading(false);
            return;
          }

          goToFlatsMapPage(updatedFilters, url);
        } catch (error) {
          if (onSubmit) {
            onSubmit('');
            setIsTransitionLoading(false);
            return;
          }

          goToFlatsListPage(filters);
        }
      } else {
        setIsTransitionLoading(false);
      }
    },
    [goToFlatsMapPage, filters, locked]
  );

  useOnClickOutside(searchBarRef, allowedClickOutside, handleClickOutside);

  return {
    currentSuggestIndex,
    autofocus,
    isTransitionLoading,
    isSearchLoading,
    searchBarRef,
    inputRef,
    handleChangeValue,
    handleClickPlaceholder,
    handleFocus,
    handleBlur,
    handleReset,
    handleCancel,
    handleKeyUp,
    handleKeyDown,
    handleMouseEnterSuggest,
    handleSubmit,
    handleClickSuggest,
    handlePressEnter
  };
};
