import { createContext, ReactChild, useCallback, useState } from 'react';

import { FilterControl } from 'core/entities/filters';
import { NOOP } from 'core/utils/NOOP';

import { useLockBodyScroll } from 'hooks/scroll/use-lock-body-scroll';

export interface FilterControlsContextType {
  calendar: FilterControl;
  guestsModal: FilterControl;
  guestsDropdown: FilterControl;
}

export const FilterControlsContext = createContext<FilterControlsContextType>({
  calendar: {
    opened: false,
    onOpen: NOOP,
    onClose: NOOP
  },
  guestsModal: {
    opened: false,
    onOpen: NOOP,
    onClose: NOOP
  },
  guestsDropdown: {
    opened: false,
    onOpen: NOOP,
    onClose: NOOP
  }
});

interface FilterControlsProviderProps {
  children: ReactChild;
}

export const FilterControlsProvider = ({ children }: FilterControlsProviderProps) => {
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [guestsModalOpened, setGuestsModalOpened] = useState(false);
  const [guestsDropdownOpened, setGuestsDropdownOpened] = useState(false);
  const { setLocked } = useLockBodyScroll();

  const onCalendarOpen = useCallback(() => {
    setCalendarOpened(true);
    setLocked(true);
  }, [calendarOpened]);

  const onCalendarClose = useCallback(() => {
    setCalendarOpened(false);
    setLocked(false);
  }, [calendarOpened]);

  const onGuestsModalOpen = useCallback(() => {
    setGuestsModalOpened(true);
    setLocked(true);
  }, [guestsModalOpened]);

  const onGuestsModalClose = useCallback(() => {
    setGuestsModalOpened(false);
    setLocked(false);
  }, [guestsModalOpened]);

  const onGuestsDropdownOpen = useCallback(() => {
    setGuestsDropdownOpened(true);
  }, [guestsDropdownOpened]);

  const onGuestsDropdownClose = useCallback(() => {
    setGuestsDropdownOpened(false);
  }, [guestsDropdownOpened]);

  return (
    <FilterControlsContext.Provider
      value={{
        calendar: { opened: calendarOpened, onOpen: onCalendarOpen, onClose: onCalendarClose },
        guestsModal: { opened: guestsModalOpened, onOpen: onGuestsModalOpen, onClose: onGuestsModalClose },
        guestsDropdown: { opened: guestsDropdownOpened, onOpen: onGuestsDropdownOpen, onClose: onGuestsDropdownClose }
      }}
    >
      {children}
    </FilterControlsContext.Provider>
  );
};
