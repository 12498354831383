import { isSameDay } from 'date-fns';
import React from 'react';

import { BookingInterval } from 'core/entities/booking';
import { NOOP } from 'core/utils/NOOP';

import { waitingForArrival, WidgetContext } from 'contexts/calendar';

import { AvailabilityChecks, IntervalCheck, useAvailability } from 'hooks/availability';

export interface DayHook {
  isArrival: boolean;
  isDeparture: boolean;
  isHovered: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  isAvailable: boolean;
  isLastHovered: boolean;
  isStartOfBooking: boolean;
  onClick: () => void;
  onHover: () => void;
}

interface SelectionState {
  arrival?: Date;
  departure?: Date;
}

function dayInRange(date: Date, range?: Optional<BookingInterval>): boolean {
  return (
    !!range &&
    (isSameDay(date, range.arrival) ||
      isSameDay(date, range.departure) ||
      (date > range.arrival && date < range.departure))
  );
}

function shouldDrawDisabled(date: Date, checks: AvailabilityChecks, state: SelectionState): boolean {
  if (!waitingForArrival(state) && checks.isStartOfBooking(date) && state.arrival) {
    return !!checks.isDepartureBlocked(state.arrival, date);
  }
  return checks.isBooked(date);
}

function dayIsAvailable(date: Date, checks: AvailabilityChecks, state: SelectionState): boolean {
  if (waitingForArrival(state) || (state.arrival && date < state.arrival)) {
    return !checks.isArrivalBlocked(date);
  }

  if (state.arrival) {
    if (checks.isArrivalBlocked(state.arrival)) {
      return false;
    }
    return isSameDay(date, state.arrival) || !checks.isDepartureBlocked(state.arrival, date);
  }

  return false;
}

function checkArrival(arrival: Date, checks: AvailabilityChecks, departure?: Date): IntervalCheck {
  const closestBlockedDay = checks.closestBlockedDay(arrival);
  const minDuration = checks.getMinDuration(arrival);
  const arrivalDate = new Date(arrival);
  const minEndDate = new Date(arrivalDate.setDate(arrivalDate.getDate() + minDuration));

  if (checks.isStartOfBooking(arrival)) {
    return IntervalCheck.onlyForDeparture;
  }
  if (checks.isBooked(arrival)) {
    if (departure && isSameDay(arrival, departure)) {
      return IntervalCheck.bookedDay;
    }
    return IntervalCheck.booked;
  }
  if (checks.isArrivalBlocked(arrival)) {
    return IntervalCheck.shortDuration;
  }
  if (!isSameDay(arrival, closestBlockedDay) && minEndDate > closestBlockedDay) {
    return IntervalCheck.shortDurationBeforeBlockedDay;
  }
  return IntervalCheck.free;
}

export function useSelectionController(day: Date): DayHook {
  const checks = useAvailability();
  const context = React.useContext(WidgetContext);

  return {
    isArrival: !!context.arrival && isSameDay(day, context.arrival),
    isDeparture: !!context.departure && isSameDay(day, context.departure),
    isHovered: dayInRange(day, context.hover),
    isDisabled: shouldDrawDisabled(day, checks, context),
    isStartOfBooking: checks.isStartOfBooking(day),
    isSelected: dayInRange(day, context.selection),
    isAvailable: dayIsAvailable(day, checks, context),
    isLastHovered: !!context.hover && isSameDay(context.hover.departure, day),
    // eslint-disable-next-line complexity
    onClick: React.useCallback(() => {
      if (context.onClick) {
        context.onClick(day);
      }
      const check = checkArrival(day, checks, context.departure);

      // хитровыдуманная автоподстановка из п.6 https://trello.com/c/ntcqVHK1/
      switch (check) {
        case IntervalCheck.onlyForDeparture:
          if (context.onComplete) {
            context.onComplete(day);
          }
          break;
        case IntervalCheck.booked:
          if (context.onComplete) {
            context.onComplete(day);
          }
          break;
        case IntervalCheck.bookedDay:
          if (context.onComplete) {
            context.onComplete(day);
          }
          break;
        case IntervalCheck.shortDuration:
          if (context.onComplete) {
            context.onComplete(checks.closestBlockedDay(day));
          }
          break;
        case IntervalCheck.shortDurationBeforeBlockedDay:
          if (context.onComplete) {
            context.onComplete(checks.closestBlockedDay(day));
          }
          break;
        default:
          NOOP();
      }
    }, [day, context.arrival, context.departure]),
    onHover: React.useCallback(() => {
      if (context.onHover && !waitingForArrival(context)) {
        context.onHover(day);
      }
    }, [day, context.arrival, context.departure])
  };
}

interface SelectionHook {
  range?: Optional<BookingInterval>;
  checkResult: IntervalCheck;
  minDuration: number;
}

export function useSelectionValidator(): SelectionHook {
  const checks = useAvailability();
  const context = React.useContext(WidgetContext);
  const range = context.selection || context.hover;
  return React.useMemo(() => {
    let checkResult = IntervalCheck.free;
    let minDuration = 1;
    if (range) {
      checkResult = checkArrival(range.arrival, checks, range.departure);
      minDuration = checks.getMinDuration(range.arrival);
      if (!checkResult && !isSameDay(range.arrival, range.departure)) {
        checkResult = checks.isDepartureBlocked(range.arrival, range.departure);
      }
    }
    return { checkResult, range: context.selection, minDuration };
  }, [context, checks]);
}
