import React from 'react';

import { Currency } from 'core/entities';
import { City } from 'core/entities/geo';
import { SearchMeta, SearchPageParams } from 'core/entities/search';
import { SuggestListItem } from 'core/entities/suggest';
import { CommonPageContext } from 'core/services/context/context';
import { getFiltersFromQueryParams } from 'core/utils/filters/search-filter';
import { NOOP } from 'core/utils/NOOP';

export enum PlaceholderValue {
  ACTIVE = 'Адрес или место',
  DEFAULT = 'Поиск жилья',
  MAP_AREA = 'Область карты',
  CENTRAL = 'Город, метро, улица, место'
}

export enum SearchBarMode {
  ACTIVE = 'ACTIVE',
  DEFAULT = 'DEFAULT',
  MAP_AREA = 'MAP_AREA',
  CITY = 'CITY',
  WITH_VALUE = 'WITH_VALUE'
}

export interface SearchBarState {
  mode: SearchBarMode;
  suggests: Array<SuggestListItem>;
  value: string;
  originalValue: string;
  savedValue: string;
  placeholderValue: PlaceholderValue;
  showCityPlaceholder: boolean;
  showFilters: boolean;
  showSuggests: boolean;
  hasCity: boolean;
  hasMapArea: boolean;
  setMode: (mode: SearchBarMode) => void;
  setSuggests: (suggests: Array<SuggestListItem>) => void;
  setValue: (value: string) => void;
  setOriginalValue: (value: string) => void;
  setSavedValue: (value: string) => void;
  setShowFilters: (value: boolean) => void;
  setIsMapArea: () => void;
}

const initialState: SearchBarState = {
  mode: SearchBarMode.DEFAULT,
  suggests: [],
  value: '',
  originalValue: '',
  savedValue: '',
  placeholderValue: PlaceholderValue.DEFAULT,
  showCityPlaceholder: false,
  showFilters: true,
  showSuggests: false,
  hasCity: false,
  hasMapArea: false,
  setMode: NOOP,
  setSuggests: NOOP,
  setValue: NOOP,
  setOriginalValue: NOOP,
  setSavedValue: NOOP,
  setShowFilters: NOOP,
  setIsMapArea: NOOP
};

export const SearchBarContext = React.createContext<SearchBarState>(initialState);

enum Action {
  SET_MODE,
  SET_SUGGESTS,
  SET_VALUE,
  SET_ORIGINAL_VALUE,
  SET_SAVED_VALUE,
  SET_SHOW_FILTERS,
  SET_IS_MAP_AREA
}

type ActionType =
  | { type: Action.SET_MODE; payload: SearchBarMode }
  | { type: Action.SET_SUGGESTS; payload: Array<SuggestListItem> }
  | { type: Action.SET_VALUE; payload: string }
  | { type: Action.SET_ORIGINAL_VALUE; payload: string }
  | { type: Action.SET_SAVED_VALUE; payload: string }
  | { type: Action.SET_SHOW_FILTERS; payload: boolean }
  | { type: Action.SET_IS_MAP_AREA };

const getPlaceholderValueByMode = (mode: SearchBarMode, hasMapArea: boolean): PlaceholderValue => {
  switch (mode) {
    case SearchBarMode.ACTIVE:
      return PlaceholderValue.ACTIVE;
    case SearchBarMode.MAP_AREA:
      return PlaceholderValue.MAP_AREA;
    case SearchBarMode.WITH_VALUE:
      if (hasMapArea) {
        return PlaceholderValue.MAP_AREA;
      }
      return PlaceholderValue.DEFAULT;
    default:
      return PlaceholderValue.DEFAULT;
  }
};

// eslint-disable-next-line complexity
const reducer = (state: SearchBarState, action: ActionType): SearchBarState => {
  switch (action.type) {
    case Action.SET_MODE:
      // eslint-disable-next-line no-case-declarations
      const updatedState = {
        ...state,
        mode: action.payload,
        placeholderValue: getPlaceholderValueByMode(action.payload, state.hasMapArea),
        showSuggests: false
      };
      if (action.payload === SearchBarMode.MAP_AREA) {
        return {
          ...updatedState,
          value: '',
          showCityPlaceholder: false,
          hasCity: false,
          hasMapArea: true
        };
      }
      if (action.payload === SearchBarMode.CITY) {
        return {
          ...updatedState,
          value: '',
          showCityPlaceholder: true,
          hasCity: true,
          hasMapArea: false
        };
      }
      if (action.payload === SearchBarMode.ACTIVE) {
        return {
          ...updatedState,
          showCityPlaceholder: false,
          showSuggests: true
        };
      }
      return updatedState;
    case Action.SET_SUGGESTS:
      return { ...state, suggests: action.payload };
    case Action.SET_VALUE:
      return { ...state, value: action.payload };
    case Action.SET_ORIGINAL_VALUE:
      return { ...state, originalValue: action.payload };
    case Action.SET_SAVED_VALUE:
      return { ...state, savedValue: action.payload };
    case Action.SET_SHOW_FILTERS:
      return { ...state, showFilters: action.payload };
    case Action.SET_IS_MAP_AREA:
      return { ...state, hasCity: false, hasMapArea: true };
    default:
      return state;
  }
};

interface SearchBarProviderProps {
  children: React.ReactChild;
  initial: SearchBarState;
}

export const SearchBarProvider = (props: SearchBarProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, props.initial);

  const providerValue: SearchBarState = {
    mode: state.mode,
    suggests: state.suggests,
    value: state.value,
    originalValue: state.originalValue,
    savedValue: state.savedValue,
    placeholderValue: state.placeholderValue,
    showCityPlaceholder: state.showCityPlaceholder,
    showFilters: state.showFilters,
    showSuggests: state.showSuggests,
    hasCity: state.hasCity,
    hasMapArea: state.hasMapArea,
    setMode: React.useCallback((mode) => dispatch({ type: Action.SET_MODE, payload: mode }), []),
    setSuggests: React.useCallback((suggests) => dispatch({ type: Action.SET_SUGGESTS, payload: suggests }), []),
    setValue: React.useCallback((value) => dispatch({ type: Action.SET_VALUE, payload: value }), []),
    setOriginalValue: React.useCallback((value) => dispatch({ type: Action.SET_ORIGINAL_VALUE, payload: value }), []),
    setSavedValue: React.useCallback((value) => dispatch({ type: Action.SET_SAVED_VALUE, payload: value }), []),
    setShowFilters: React.useCallback((value) => dispatch({ type: Action.SET_SHOW_FILTERS, payload: value }), []),
    setIsMapArea: React.useCallback(() => dispatch({ type: Action.SET_IS_MAP_AREA }), [])
  };

  return <SearchBarContext.Provider value={providerValue}>{props.children}</SearchBarContext.Provider>;
};

export const getSearchBarInitialState = (
  context: CommonPageContext,
  meta?: SearchMeta,
  city?: Optional<City>,
  searchFilters?: SearchPageParams,
  currency?: Currency
): SearchBarState => {
  let hasMapArea = Boolean(meta?.filters.area);
  let hasCity = Boolean(city);
  let mode = SearchBarMode.DEFAULT;
  let value = '';
  let placeholderValue = PlaceholderValue.DEFAULT;
  let showCityPlaceholder = false;

  if (searchFilters && currency) {
    const filters = getFiltersFromQueryParams(searchFilters, currency);
    if (filters.pointName) {
      mode = SearchBarMode.WITH_VALUE;
      value = filters.pointName;
      hasCity = false;
      hasMapArea = true;
    } else if ((!hasMapArea && hasCity) || context.seo.is_landing) {
      mode = SearchBarMode.CITY;
      showCityPlaceholder = true;
    } else if (hasMapArea) {
      placeholderValue = PlaceholderValue.MAP_AREA;
      hasCity = false;
    }
  }

  return {
    ...initialState,
    mode,
    value,
    originalValue: value,
    savedValue: value,
    placeholderValue,
    showCityPlaceholder,
    hasCity,
    hasMapArea
  };
};
