import styles from './counter.module.css';

import AddIcon from './icons/add.svg';
import SubstractIcon from './icons/substract.svg';

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

interface CounterProps {
  className?: string;
  value: number;
  limits: {
    min: number;
    max?: number;
  };
  uncheckable?: boolean;
  onChange: (value: number) => void;
}

export const Counter = ({ className, value = 1, limits, uncheckable = false, onChange }: CounterProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleAdd = useCallback(() => {
    if (limits.max && currentValue < limits.max) {
      setCurrentValue((prevValue) => prevValue + 1);
    }
  }, [currentValue]);

  const handleSubstract = useCallback(() => {
    if (currentValue > limits.min) {
      setCurrentValue((prevValue) => prevValue - 1);
    }
  }, [currentValue]);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  const decBtnClasses = classNames(styles.btn, styles.btnDec, { [styles.btnDisabled]: currentValue === limits.min });
  const incBtnClasses = classNames(styles.btn, styles.btnInc, { [styles.btnDisabled]: currentValue === limits.max });

  return (
    <div className={classNames(styles.root, className, { [styles.uncheckable]: uncheckable })}>
      <input type='hidden' value={value} />
      <button className={decBtnClasses} onClick={handleSubstract}>
        <SubstractIcon />
      </button>
      <span className={styles.counterValue}>{value}</span>
      <button className={incBtnClasses} onClick={handleAdd}>
        <AddIcon />
      </button>
    </div>
  );
};
