// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import classNames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT } from 'core/constants/filters';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { updateGuestsCookie } from 'core/utils/filters/cookie-filters';
import { WordForms } from 'core/utils/word-forms';

import { FilterControlsContext } from 'contexts/filters/filter-control';

import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { Button } from 'components/common/button/button';
import { Dropdown, useDropdown } from 'components/common/dropdown/dropdown';
import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import GuestsIcon from 'components/search/controls/icons/guests.svg';
import { GuestsFilter } from 'components/search/filters/guests/guests';

interface GuestsControlProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  mainHost: string;
  checkAdult: () => void;
  adultChecked: boolean;
  notFilter?: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const GuestsControl = ({
  filters,
  setFilters,
  mainHost,
  checkAdult,
  adultChecked,
  notFilter,
  isLoading,
  setLoading
}: GuestsControlProps) => {
  const { guestsDropdown } = useContext(FilterControlsContext);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useDropdown(dropdownRef);
  const [totalGuests, setTotalGuests] = useState(0);
  const [adults, setAdults] = useState(filters.guests.adults);
  const [children, setChildren] = useState(filters.guests.children);
  const [babies, setBabies] = useState(filters.guests.babies);

  useEffect(() => {
    // sync filters
    setAdults(filters.guests.adults);
    setChildren(filters.guests.children);
    setBabies(filters.guests.babies);

    setTotalGuests(filters.guests.adults + filters.guests.children + filters.guests.babies);
  }, [filters]);

  const handleAdultsChange = useCallback((value: number) => {
    setAdults(value);
  }, []);

  const handleKidsChange = useCallback((value: number) => {
    setChildren(value);
  }, []);

  const handleBabiesChange = useCallback((value: number) => {
    setBabies(value);
  }, []);

  const handleApply = useCallback(() => {
    if (
      filters.guestsSelected &&
      adults === filters.guests.adults &&
      babies === filters.guests.babies &&
      children === filters.guests.children
    ) {
      guestsDropdown.onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }
    const updatedFilters: Filters = {
      ...filters,
      guestsSelected: true,
      guests: {
        adults,
        children,
        babies
      }
    };
    if (setLoading) {
      setLoading((prev) => ({ ...prev, guests: true }));
    }
    setFilters(updatedFilters);
    updateGuestsCookie(updatedFilters.guests, mainHost);
    guestsDropdown.onClose();
  }, [filters, adults, children, babies]);

  const handleApplyWithAdultCheck = useCallback(() => {
    handleApply();
    checkAdult();
  }, [filters, adults, children, babies, handleApply, checkAdult]);

  const handleClose = () => {
    guestsDropdown.onClose();
    setAdults(filters.guests.adults);
    setChildren(filters.guests.children);
    setBabies(filters.guests.babies);
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useOnClickOutside(dropdownRef, guestsDropdown.opened, handleClose);

  const showGuests = notFilter ? true : filters.guestsSelected;

  const buttonText =
    (showGuests && totalGuests >= 1) || (totalGuests === 1 && adultChecked)
      ? new WordForms('гость', 'гостей', 'гостей').getPlural(totalGuests, '\xa0', true)
      : 'Гости';

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.notInFilters]: notFilter
      })}
    >
      <DropdownButton
        text={buttonText}
        icon={<GuestsIcon />}
        size={notFilter ? 'lg' : 'md'}
        isActive={guestsDropdown.opened}
        onClick={guestsDropdown.onOpen}
        isLoading={isLoading ? isLoading.guests : false}
      />
      <Dropdown
        className={classNames(styles.dropdown, styles.dropdownGuests)}
        ref={dropdownRef}
        opened={guestsDropdown.opened}
      >
        <div className={styles.dropdownContent}>
          <GuestsFilter
            adults={adults}
            kids={children}
            babies={babies}
            onChangeAdults={handleAdultsChange}
            onChangeKids={handleKidsChange}
            onChangeBabies={handleBabiesChange}
          />
          <Button text={APPLY_FILTER_BUTTON_TEXT} onClick={handleApplyWithAdultCheck} block />
        </div>
      </Dropdown>
    </div>
  );
};
