import get from 'lodash/get';
import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

export enum BrowserFamily {
  chrome,
  yandex,
  yandexWithAlice,
  firefox,
  safari,
  node,
  other
}

const isYandexBrowserWithAlice = (ua: string): boolean => Boolean(ua.match(/YaApp|YaSearchBrowser/i));

const detectYandexBrowserType = (ua: string): BrowserFamily => {
  if (isYandexBrowserWithAlice(ua)) {
    return BrowserFamily.yandexWithAlice;
  }
  return BrowserFamily.yandex;
};

export function detectBrowserFamily(): BrowserFamily {
  if (typeof navigator === 'undefined') {
    return BrowserFamily.node;
  }
  const ua = get(navigator, 'userAgent', '');
  const parser = new UAParser(ua);
  const engine = parser.getEngine().name;
  const name = parser.getBrowser().name;
  switch (name) {
    case 'Firefox':
      return BrowserFamily.firefox;
    case 'Safari':
      return BrowserFamily.safari;
    case 'Chrome':
      return BrowserFamily.chrome;
    case 'Yandex':
      return detectYandexBrowserType(ua);
    default:
      switch (engine) {
        case 'Gecko':
          return BrowserFamily.firefox;
        case 'WebKit':
          return BrowserFamily.chrome;
        default:
          return BrowserFamily.other;
      }
  }
}

export function useBrowser() {
  return useMemo(detectBrowserFamily, []);
}

/*
UA EXAMPLES
ya start (android)          YaApp_Android/23.36.1         YaSearchBrowser/23.36.1
ya with alice (ios)         YaBrowser/23.5.0.1761.10      YaApp_iOS/2305.0             YaApp_iOS_Browser/2305.0
ya with alice (android)     YaSearchBrowser/23.37         YaSearchApp/23.37
ya browser (ios)            YaBrowser/23.5.1.280.10
ya browser (android)        YaBrowser/23.3.6.40.00
*/
