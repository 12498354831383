import styles from './dropdown-button.module.css';

import Arrow from './icons/arrow.svg';

import classNames from 'classnames';
import { ReactNode } from 'react';

import { Spinner, SpinnerColors } from 'components/common/spinner/spinner';
import { Text, TextColors } from 'components/common/text/text';

type Size = 'md' | 'lg';

interface DropdownButtonProps {
  className?: string;
  text: string | ReactNode;
  compactText?: string | ReactNode;
  compact?: boolean;
  icon?: ReactNode;
  block?: boolean;
  size?: Size;
  isActive?: boolean;
  onClick: () => void;
  hasError?: boolean;
  noBackground?: boolean;
  isLoading?: boolean;
}

export function DropdownButton(props: DropdownButtonProps) {
  const {
    className,
    text,
    compactText,
    compact = false,
    icon,
    block = false,
    size = 'lg',
    isActive = false,
    onClick,
    hasError,
    noBackground = false,
    isLoading = false
  } = props;

  const classes = classNames(
    styles.dropdownButton,
    className,
    styles[size],
    { [styles.block]: block },
    { [styles.compact]: compact },
    { [styles.isActive]: isActive },
    { [styles.hasError]: hasError },
    { [styles.noBackground]: noBackground }
  );

  const handleClick = () => {
    setTimeout(() => {
      onClick();
    }, 0);
  };

  return (
    <button className={classes} onClick={handleClick}>
      <Text className={styles.text} size={16} color={TextColors.blue}>
        {text}
      </Text>

      {compact && compactText && (
        <Text className={styles.compactText} size={14} desktopSize={16} color={TextColors.blue}>
          {compactText}
        </Text>
      )}

      {/* Показываем иконку, если она передана */}
      {icon && !isLoading && <div className={styles.dropdownIcon}>{icon}</div>}
      {isLoading && <Spinner classname={styles.spinner} size={16} color={SpinnerColors.blue} />}

      {/* Иначе показываем стрелку */}
      {!icon && !isLoading && (
        <div className={styles.dropdownArrow}>
          <Arrow />
        </div>
      )}
    </button>
  );
}
