import { Entity } from 'core/entities';
import { City } from 'core/entities/geo';
import { Photo } from 'core/entities/photos';

export enum BuildingType {
  flat = 'flat',
  cottage = 'cottage',
  room = 'room'
}

export enum BathroomType {
  disabled = 'disabled',
  privated = 'privated',
  shared = 'shared'
}

export enum KitchenType {
  disabled = 'disabled',
  privated = 'privated',
  shared = 'shared'
}

export interface Housing extends Entity {
  address: string;
  city: City;
  buildingType: BuildingType;
  rooms: number;
  mainPhoto: Photo;
  photos?: Photo[];
  url: string;
}
