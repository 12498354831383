import styles from './calendar-modal.module.css';

import classNames from 'classnames';
import { format, isAfter, isSameDay } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { Dispatch, ReactElement, SetStateAction, useCallback } from 'react';

import { dateFormat } from 'core/constants/date-formats';
import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import {
  CALENDAR_BOOKED_DAY_TEXT,
  CALENDAR_BOOKED_TEXT,
  CALENDAR_MODAL_TITLE_TEXT,
  CALENDAR_ONLY_FOR_DEPARTURES_TEXT,
  CALENDAR_SHORT_DURATION_BEFORE_BLOCK_DAY_TEXT,
  CALENDAR_SHORT_DURATION_TEXT
} from 'core/constants/filters';
import { WORD_FORM_FULL_DAYS } from 'core/constants/word-forms';
import { BookingInterval } from 'core/entities/booking';
import { DatesFilters, FilterControl, Filters, FiltersLoadingType } from 'core/entities/filters';
import { getDifferenceInDays } from 'core/utils/calendar';
import { updateDatesCookie } from 'core/utils/filters/cookie-filters';
import { WordForms } from 'core/utils/word-forms';

import { CalendarProvider } from 'contexts/calendar';

import { IntervalCheck } from 'hooks/availability';

import { Ruler } from 'components/calendar/ruler/ruler';
import { Modal } from 'components/common/modal/modal';
import { CalendarFilter } from 'components/search/filters/calendar/calendar';

interface CalendarModalProps {
  btnText: string;
  calendar: FilterControl;
  calendarError: Optional<string>;
  dates: DatesFilters;
  filters: Filters;
  footerChildren: ReactElement;
  mainHost: string;
  setCalendarError: Dispatch<SetStateAction<Optional<string>>>;
  setDates: Dispatch<SetStateAction<DatesFilters>>;
  setFilters: Dispatch<SetStateAction<Filters>>;
  applyWithoutDates?: boolean;
  isBtnDisabled?: boolean;
  isBtnLoading?: boolean;
  noCheckValidation?: boolean;
  noMinDuration?: boolean;
  urlToSearch?: string;
  withSkipBtn?: boolean;
  onApply?: () => void;
  onChange?: (range: BookingInterval) => Promise<void>;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const CalendarModal = ({
  btnText,
  calendar,
  calendarError,
  dates,
  filters,
  footerChildren,
  mainHost,
  setCalendarError,
  setDates,
  setFilters,
  applyWithoutDates,
  isBtnDisabled,
  isBtnLoading,
  noMinDuration,
  noCheckValidation,
  urlToSearch,
  withSkipBtn,
  onApply,
  onChange,
  setLoading
}: CalendarModalProps) => {
  let initialCalendarState;
  const isSubmutBtnDisabled = isBtnDisabled ? isBtnDisabled || !!calendarError : !!calendarError;

  if (filters.dates.arrival && filters.dates.departure) {
    const arrivalDate = new Date(filters.dates.arrival);
    const minAllowedDate = new Date();
    // учитывается и вчерашняя дата из-за разности часовых поясов
    minAllowedDate.setDate(minAllowedDate.getDate() - 1);

    if (isSameDay(arrivalDate, minAllowedDate) || isAfter(arrivalDate, minAllowedDate)) {
      initialCalendarState = {
        arrival: arrivalDate,
        departure: new Date(filters.dates.departure)
      };
    }
  }

  const handleChange = useCallback(
    async (range: Optional<BookingInterval>) => {
      if (range) {
        setDates({
          arrival: format(new Date(range.arrival), dateFormat.iso),
          departure: format(new Date(range.departure), dateFormat.iso),
          duration: getDifferenceInDays(new Date(range.departure), new Date(range.arrival))
        });

        if (onChange) {
          await onChange(range);
        }
      } else {
        setDates({
          arrival: null,
          departure: null,
          duration: null
        });
      }
    },
    [onChange]
  );

  const handleApply = useCallback(() => {
    if (isEqual(dates, filters.dates) && !applyWithoutDates) {
      calendar.onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }

    const updatedFilters: Filters = {
      ...filters,
      dates
    };
    if (!calendarError) {
      if (setLoading) {
        setLoading((prev) => ({ ...prev, calendar: true }));
      }

      if (onApply) {
        onApply();
      } else {
        setFilters(updatedFilters);
      }

      updateDatesCookie(updatedFilters.dates, mainHost);
    }

    calendar.onClose();
  }, [filters, dates, urlToSearch, onApply]);

  const showCalendarError = useCallback((checkResult: number, duration: number) => {
    const durationText = new WordForms(
      WORD_FORM_FULL_DAYS.ONE,
      WORD_FORM_FULL_DAYS.FEW,
      WORD_FORM_FULL_DAYS.MANY
    ).getPlural(duration, '\xa0', true);

    switch (checkResult) {
      case IntervalCheck.onlyForDeparture:
        setCalendarError(CALENDAR_ONLY_FOR_DEPARTURES_TEXT);
        break;
      case IntervalCheck.bookedDay:
        setCalendarError(CALENDAR_BOOKED_DAY_TEXT);
        break;
      case IntervalCheck.booked:
        setCalendarError(CALENDAR_BOOKED_TEXT);
        break;
      case IntervalCheck.shortDurationBeforeBlockedDay:
        setCalendarError(CALENDAR_SHORT_DURATION_BEFORE_BLOCK_DAY_TEXT(durationText));
        break;
      case IntervalCheck.shortDuration:
        setCalendarError(CALENDAR_SHORT_DURATION_TEXT(durationText));
        break;
      default:
        setCalendarError(null);
    }
  }, []);

  const handleClose = () => {
    calendar.onClose();
    setDates(filters.dates);
  };

  return (
    <Modal
      className={styles.calendarModal}
      title={CALENDAR_MODAL_TITLE_TEXT}
      opened={calendar.opened}
      onClose={handleClose}
      onApply={handleApply}
      hasFooter
      isBtnDisabled={isSubmutBtnDisabled}
      isBtnLoading={isBtnLoading}
      headerChildren={<Ruler className={styles.ruler} />}
      footerChildren={footerChildren}
      footerClassName={classNames({ [styles.footerWithSkipBtn]: withSkipBtn })}
      btnText={btnText}
    >
      <CalendarProvider onSelectRange={handleChange} initial={initialCalendarState}>
        <CalendarFilter
          showCalendarError={showCalendarError}
          noMinDuration={noMinDuration}
          noCheckValidation={noCheckValidation}
        />
      </CalendarProvider>
    </Modal>
  );
};
