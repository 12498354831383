import styles from './suggests.module.css';

import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SUGGESTS_DESKTOP_LIMIT } from 'core/constants/suggests';
import { SuggestListItem } from 'core/entities/suggest';

import { useWindowDimensions } from 'hooks/use-window-dimensions';

import { Suggest } from 'components/search-bar/suggests/suggest/suggest';

interface SuggestsProps {
  suggests: Array<SuggestListItem>;
  currentSuggestIndex?: number;
  onClickSuggest: (suggest: SuggestListItem) => void;
  onMouseEnterSuggest?: (currentSuggestIndex: number) => void;
  show: boolean;
  isMobile?: boolean;
  isCentral?: boolean;
}

export const Suggests = (props: SuggestsProps) => {
  const { isMobile = false, isCentral } = props;

  if (props.suggests.length === 0 || !props.show) {
    return null;
  }

  const dimensions = useWindowDimensions();
  const [rootHeight, setRootHeight] = useState<number | string>(0);

  useEffect(() => {
    const indentFromTop = 117;
    if (isMobile) {
      setRootHeight(dimensions.height - indentFromTop);
    } else {
      setRootHeight('auto');
    }
  }, [dimensions.height]);

  const rootClasses = classNames(
    styles.root,
    { [styles.mobile]: isMobile },
    { [styles.desktop]: !isMobile },
    { [styles.central]: isCentral }
  );

  let filteredSuggests = props.suggests.slice(0, SUGGESTS_DESKTOP_LIMIT);

  if (isMobile) {
    filteredSuggests = props.suggests;
  }

  return (
    <div className={rootClasses} style={{ height: rootHeight }}>
      <ul>
        {filteredSuggests.map((suggest, index) => (
          <Suggest
            key={index}
            index={index}
            currentIndex={props.currentSuggestIndex}
            suggest={suggest}
            onClick={props.onClickSuggest}
            onMouseEnter={props.onMouseEnterSuggest}
          />
        ))}
      </ul>
    </div>
  );
};
