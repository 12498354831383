import { City, Point } from 'core/entities/geo';

export enum SuggestType {
  city = 'city',
  region = 'region',
  place = 'place',
  street = 'street',
  addressBlock = 'addressBlock',
  administrativeArea = 'administrativeArea',
  houseNumber = 'houseNumber',
  locality = 'locality',
  unknown = 'unknown'
}

export interface SuggestListItem {
  id: string;
  name: string;
  type: SuggestType;
  city?: City;
  point?: Point;
  url: string;
  description?: string;
}

export interface Suggest {
  id: string;
  name: string;
  city: City;
  point: Point;
  url: string;
  parts: {
    label: string;
    countryCode: string;
    countryName: string;
    state: string;
    county: string;
    city: string;
    district: string;
    street: string;
    postalCode: string;
  };
}
