// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import classNames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT, GUESTS_MODAL_TITLE_TEXT } from 'core/constants/filters';
import { WORD_FORM_GUEST } from 'core/constants/word-forms';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { updateGuestsCookie } from 'core/utils/filters/cookie-filters';
import { WordForms } from 'core/utils/word-forms';

import { FilterControlsContext } from 'contexts/filters/filter-control';

import { useHasChekedCalendar, useHasChekedGuests } from 'hooks/filters';

import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { Modal } from 'components/common/modal/modal';
import GuestsIcon from 'components/search/controls/icons/guests.svg';
import { GuestsFilter } from 'components/search/filters/guests/guests';

export interface GuestsModalControlProps {
  className?: string;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  mainHost: string;
  isMobileMode: boolean;
  notFilter?: boolean;
  checkAdult?: () => void;
  adultChecked?: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const GuestsModalControl = ({
  className,
  filters,
  setFilters,
  mainHost,
  isMobileMode,
  notFilter,
  checkAdult,
  adultChecked,
  isLoading,
  setLoading
}: GuestsModalControlProps) => {
  const { guestsModal } = useContext(FilterControlsContext);
  const [totalGuests, setTotalGuests] = useState(0);
  const [adults, setAdults] = useState(filters.guests.adults);
  const [children, setChildren] = useState(filters.guests.children);
  const [babies, setBabies] = useState(filters.guests.babies);
  const hasChekedGuests = useHasChekedGuests(filters, adultChecked);
  const hasChekedCalendar = useHasChekedCalendar(filters);

  useEffect(() => {
    // sync filters
    setAdults(filters.guests.adults);
    setChildren(filters.guests.children);
    setBabies(filters.guests.babies);

    setTotalGuests(filters.guests.adults + filters.guests.children + filters.guests.babies);
  }, [filters]);

  useEffect(() => {
    if (!isMobileMode) {
      guestsModal.onClose();
    }
  }, [isMobileMode]);

  const handleAdultsChange = useCallback((value: number) => {
    setAdults(value);
  }, []);

  const handleKidsChange = useCallback((value: number) => {
    setChildren(value);
  }, []);

  const handleBabiesChange = useCallback((value: number) => {
    setBabies(value);
  }, []);

  const handleApply = useCallback(() => {
    if (
      filters.guestsSelected &&
      adults === filters.guests.adults &&
      babies === filters.guests.babies &&
      children === filters.guests.children
    ) {
      guestsModal.onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }
    const updatedFilters: Filters = {
      ...filters,
      guestsSelected: true,
      guests: {
        adults,
        children,
        babies
      }
    };
    if (setLoading) {
      setLoading((prev) => ({ ...prev, guests: true }));
    }
    setFilters(updatedFilters);
    updateGuestsCookie(updatedFilters.guests, mainHost);
    if (checkAdult) {
      checkAdult();
    }
    guestsModal.onClose();
  }, [filters, adults, children, babies]);

  const handleClose = () => {
    guestsModal.onClose();
    setAdults(filters.guests.adults);
    setChildren(filters.guests.children);
    setBabies(filters.guests.babies);
  };

  let compactText = hasChekedGuests ? totalGuests : '?';
  if (notFilter) {
    compactText = new WordForms(WORD_FORM_GUEST.ONE, WORD_FORM_GUEST.FEW, WORD_FORM_GUEST.MANY).getPlural(
      totalGuests,
      '\xa0',
      true
    );
  }

  return (
    <div className={classNames(styles.wrapper, { [styles.notInFilters]: notFilter })}>
      <DropdownButton
        className={classNames(styles.btn, className)}
        text='Гости'
        compact={hasChekedGuests || hasChekedCalendar}
        compactText={compactText}
        icon={<GuestsIcon />}
        size={notFilter ? 'lg' : 'md'}
        isActive={guestsModal.opened}
        onClick={guestsModal.onOpen}
        isLoading={isLoading ? isLoading.guests : false}
      />
      <Modal
        title={GUESTS_MODAL_TITLE_TEXT}
        opened={guestsModal.opened}
        onClose={handleClose}
        onApply={handleApply}
        hasFooter
        btnText={APPLY_FILTER_BUTTON_TEXT}
      >
        <GuestsFilter
          adults={adults}
          kids={children}
          babies={babies}
          onChangeAdults={handleAdultsChange}
          onChangeKids={handleKidsChange}
          onChangeBabies={handleBabiesChange}
        />
      </Modal>
    </div>
  );
};
