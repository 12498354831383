import { set } from 'js-cookie';

import { DatesFilters, GuestsFilters } from 'core/entities/filters';

export const updateGuestsCookie = (guests: GuestsFilters, domain: string) => {
  const cookieGuests = {
    adults: guests.adults ? guests.adults : 1,
    children: guests.children ? guests.children : 0,
    babies: guests.babies ? guests.babies : 0
  };
  set('booking-form-guests', JSON.stringify(cookieGuests), { expires: 3, domain });
};

export const updateDatesCookie = (dates: DatesFilters, domain: string) => {
  const cookieDates = {
    arrival: dates.arrival,
    departure: dates.departure
  };
  if (cookieDates.arrival && cookieDates.departure) {
    set('booking-form-dates', JSON.stringify(cookieDates), { expires: 3, domain });
  }
};
