import { MutableRefObject, useCallback, useRef, useState } from 'react';

export function useTimer<T>(inactive: T, defaultActive?: T) {
  const [active, setActive] = useState(inactive);
  const timeout: MutableRefObject<number | null> = useRef(null);
  const reset = useCallback(() => {
    timeout.current = null;
    setActive(inactive);
  }, []);
  const start = useCallback((duration: number, next = defaultActive) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (typeof next !== 'undefined') {
      setActive(next);
    }
    timeout.current = setTimeout(reset, duration) as unknown as number;
  }, []);
  return { active, start };
}
