import { RUS_ID } from 'core/constants/currency';
import { Currency } from 'core/entities';
import { Filters, FiltersLoadingType } from 'core/entities/filters';

export const DEFAULT_FILTERS: Filters = {
  buildingTypes: {},
  dates: {
    arrival: null,
    departure: null,
    duration: null
  },
  features: {},
  rooms: {},
  roomsExtraOptions: {},
  guests: {
    adults: 1,
    children: 0,
    babies: 0
  },
  guestsSelected: false,
  sleepingPlaces: 1,
  minBeds: 1,
  prices: {
    filtered: {
      min: 500,
      max: 50000
    },
    range: {
      min: 100,
      max: 300000
    }
  },
  map: {
    minLat: null,
    minLng: null,
    maxLat: null,
    maxLng: null,
    lat: null,
    lng: null,
    zoom: null
  }
};

export const DEFAULT_CURRENCY: Currency = {
  id: RUS_ID,
  name: '',
  exchangeRate: 1,
  entity: '₽',
  maxPrice: 300000,
  minPrice: 100,
  priority: 0,
  codename: '',
  slider_steps: []
};

export const DEFAULT_PHOTO_COLOR = '#888888';
export const DEFAULT_PHOTOCHECK_PHOTO_COLOR = '#D1E2F7';

export const DEFAULT_LOADINGS: FiltersLoadingType = {
  calendar: false,
  guests: false,
  buildingTypes: false,
  prices: false,
  features: false,
  mobileFilters: false
};
