import styles from './slider.module.css';

import classNames from 'classnames';
import { useCallback, useState } from 'react';
import ReactSlider from 'react-slider';

import { Currency } from 'core/entities';
import { convertCurrencyValue } from 'core/utils/currency';

import { Price } from 'components/common/price/price';
import { Text } from 'components/common/text/text';

interface SliderProps {
  className?: string;
  value: Array<number>;
  min: number;
  max: number;
  currencies: {
    current: Currency;
    primary: Currency;
  };
  isLandingWithPrice: boolean;
  onChange: (values: Array<number>) => void;
}

export const Slider = (props: SliderProps) => {
  const [touched, setTouched] = useState(false);
  const steps = props.currencies.current.slider_steps ? props.currencies.current.slider_steps : [];

  const convertToSliderValue = useCallback(
    (value: number): number => {
      if (value === 1) {
        return 0;
      }
      let result = 0;
      while (steps[result + 1] <= value) {
        result = result + 1;
      }
      return Math.min(result, steps.length - 1);
    },
    [steps]
  );

  const convertFromSliderValue = useCallback(
    (value?: number): number => {
      if (typeof value !== 'number') {
        return steps[steps.length - 1];
      }
      return steps[value];
    },
    [steps]
  );

  const handleChange = useCallback(
    (values: Array<number>) => {
      setTouched(true);
      const minValue = convertFromSliderValue(values[0]);
      const value = convertFromSliderValue(values[1]);
      if (minValue >= props.min && value <= props.max && minValue <= value) {
        props.onChange([minValue, value]);
      }
    },
    [convertFromSliderValue]
  );

  const defaultValue = [
    convertToSliderValue(convertCurrencyValue(props.value[0], props.currencies.current, props.currencies.primary)),
    convertToSliderValue(convertCurrencyValue(props.value[1], props.currencies.current, props.currencies.primary))
  ];

  return (
    <div className={classNames(styles.root, props.className)}>
      <Text className={styles.title} size={16}>
        От{' '}
        {!touched && props.isLandingWithPrice
          ? convertCurrencyValue(props.value[0], props.currencies.current, props.currencies.primary)
          : props.value[0]}{' '}
        до{' '}
        {
          <Price
            currency={props.currencies.current}
            value={
              !touched && props.isLandingWithPrice
                ? convertCurrencyValue(props.value[1], props.currencies.current, props.currencies.primary)
                : props.value[1]
            }
          />
        }{' '}
        за сутки
      </Text>
      <ReactSlider
        className={styles.slider}
        trackClassName={styles.track}
        thumbClassName={styles.thumb}
        thumbActiveClassName={styles.thumbActive}
        onChange={handleChange}
        min={1}
        max={99}
        defaultValue={
          !touched && props.isLandingWithPrice
            ? defaultValue
            : [convertToSliderValue(props.value[0]), convertToSliderValue(props.value[1])]
        }
      />
    </div>
  );
};
