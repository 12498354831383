import styles from './guests.module.css';

import classNames from 'classnames';

import { Counter } from 'components/common/counter/counter';
import { Text, TextColors } from 'components/common/text/text';

interface GuestsFilterProps {
  className?: string;
  adults: number;
  kids: number;
  babies: number;
  onChangeAdults: (value: number) => void;
  onChangeKids: (value: number) => void;
  onChangeBabies: (value: number) => void;
}

export const GuestsFilter = (props: GuestsFilterProps) => {
  return (
    <div className={classNames(styles.root, props.className)}>
      <div className={styles.variant}>
        <Text size={16} color={TextColors.black}>
          Взрослых гостей
        </Text>
        <Counter value={props.adults} onChange={props.onChangeAdults} limits={{ min: 1, max: 15 }} />
      </div>
      <div className={styles.variant}>
        <Text size={16} color={TextColors.black}>
          Детей 2-12 лет
        </Text>
        <Counter value={props.kids} onChange={props.onChangeKids} limits={{ min: 0, max: 15 }} />
      </div>
      <div className={styles.variant}>
        <Text size={16} color={TextColors.black}>
          Малышей до 2 лет
        </Text>
        <Counter value={props.babies} onChange={props.onChangeBabies} limits={{ min: 0, max: 15 }} />
      </div>
    </div>
  );
};
