import styles from './rooms-extra-options.module.css';

import classNames from 'classnames';

import { RoomsExtraOptionsFilters } from 'core/entities/filters';

import { Checkbox, OnChangeCheckbox } from 'components/common/checkbox/checkbox';

interface RoomsExtraOptionsProps {
  className?: string;
  roomsExtraOptions: RoomsExtraOptionsFilters;
  onChange: OnChangeCheckbox;
}

export const RoomsExtraOptionsFilter = ({ className, roomsExtraOptions, onChange }: RoomsExtraOptionsProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.variants}>
        <Checkbox
          className={styles.variant}
          variant='switch'
          id='bathroom'
          value='bathroom'
          checked={roomsExtraOptions.bathroom}
          onToggle={onChange}
        >
          Личная ванная комната
        </Checkbox>
        <Checkbox
          className={styles.variant}
          variant='switch'
          id='kitchen'
          value='kitchen'
          checked={roomsExtraOptions.kitchen}
          onToggle={onChange}
        >
          Личная или общая кухня
        </Checkbox>
      </div>
    </div>
  );
};
