import styles from './search-input.module.css';

import classNames from 'classnames';
import React from 'react';

import { WEBVISOR_ALLOW_RECORD_CLASS } from 'core/constants/yandex-api';
import { City } from 'core/entities/geo';

import { PlaceholderValue, SearchBarState } from 'contexts/search/search-bar';

import { SearchBar } from 'hooks/search-bar';

import { Placeholder } from 'components/search-bar/placeholder/placeholder';

interface SearchInputProps {
  searchBarContext: SearchBarState;
  searchBar: SearchBar;
  city?: Optional<City>;
  isActive: boolean;
  isCentral?: boolean;
  stdPlaceholder?: boolean;
}

export const SearchInput = ({
  searchBarContext,
  searchBar,
  city,
  isActive,
  isCentral,
  stdPlaceholder
}: SearchInputProps) => {
  return (
    <>
      {searchBarContext.showCityPlaceholder ? (
        <Placeholder city={city} onClick={searchBar.handleClickPlaceholder} />
      ) : (
        <input
          className={classNames(
            styles.search,
            { [styles.searchActive]: isActive },
            { [styles.central]: isCentral },
            WEBVISOR_ALLOW_RECORD_CLASS
          )}
          ref={searchBar.inputRef}
          type='text'
          placeholder={!isCentral || stdPlaceholder ? searchBarContext.placeholderValue : PlaceholderValue.CENTRAL}
          value={searchBarContext.value}
          autoFocus={searchBar.autofocus}
          onChange={searchBar.handleChangeValue}
          onFocus={searchBar.handleFocus}
          onBlur={searchBar.handleBlur}
          onKeyUp={searchBar.handleKeyUp}
          onKeyDown={searchBar.handleKeyDown}
        />
      )}
    </>
  );
};
