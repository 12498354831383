export interface Entity {
  id: number;
}

export interface Currency extends Entity {
  name: string;
  exchangeRate: number;
  entity: string;
  maxPrice: number;
  minPrice: number;
  className?: string;
  priority: number;
  slider_steps?: number[];
  codename: string;
}

export interface CurrenciesInfo {
  current: Currency;
  primary: Currency;
  entries: Currency[];
}

export interface Achivements {
  [key: string]: boolean;
}

export interface Price {
  amount: number;
  currency: Currency;
}

export interface PriceRange {
  id?: number;
  total: number;
  min: number;
  max: number;
  // TODO: Пока у валют нет API передается число === id
  currency: number;
  // currency: Currency;
}

export interface Cost extends Omit<Price, 'amount'> {
  days: number;
  total: number;
}

export interface DailyPrice extends Price {
  day: Date;
}

export enum FooterSectionCodeName {
  social = 'socialLinksSection',
  apps = 'applicationsSection'
}

interface FooterLink {
  codeName: string;
  name: string;
  url: string;
}

export interface FooterLinksSection {
  codeName: FooterSectionCodeName | string;
  name: string;
  links: Array<FooterLink>;
}

export interface FooterContext {
  fullFooter?: Array<FooterLinksSection>;
  shortFooter?: Array<FooterLinksSection>;
}

export enum LogType {
  all = 'all',
  occupancy = 'occupancy',
  minStay = 'minStay',
  price = 'price'
}

export enum LastAppVersion {
  android = 'android',
  ios = 'ios',
  none = ''
}

export interface Affixes {
  prefix?: string;
  postfix?: string;
}

export interface WordFormsVariants {
  one: string;
  few: string;
  many: string;
}
