import styles from './filters-toggle.module.css';

import classNames from 'classnames';

import ArrowDropdownIcon from 'components/common/icons/arrow-dropdown.svg';
import { Noindex } from 'components/common/noindex/noindex';
import { Text, TextColors } from 'components/common/text/text';

interface FiltersToggleProps {
  className?: string;
  onClick: () => void;
}

export const FiltersToggle = (props: FiltersToggleProps) => {
  return (
    <div className={classNames(styles.root, props.className)} onClick={props.onClick}>
      <div className={styles.textWrapper}>
        <Noindex>
          <Text className={styles.text} size={16} color={TextColors.blue}>
            Поиск и подбор
          </Text>
        </Noindex>
        <ArrowDropdownIcon />
      </div>
    </div>
  );
};
