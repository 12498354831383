import styles from './calendar.module.css';

import { CalendarWithScroll } from 'components/calendar/calendarWithScroll';

interface CalendarFilterProps {
  showCalendarError?: (checkResult: number, minDuration: number) => void;
  noMinDuration?: boolean;
  noCheckValidation?: boolean;
}

export const CalendarFilter = ({ showCalendarError, noMinDuration, noCheckValidation }: CalendarFilterProps) => {
  return (
    <div className={styles.root}>
      <CalendarWithScroll
        showCalendarError={showCalendarError}
        noMinDuration={noMinDuration}
        noCheckValidation={noCheckValidation}
      />
    </div>
  );
};
