import { useEffect, useState } from 'react';

export const useLockBodyScroll = (initialLocked = false, withWindowWidth = true) => {
  const [locked, setLocked] = useState(initialLocked);

  useEffect(() => {
    if (!locked) {
      return;
    }

    const fixedHeader = document.getElementById('fixed-header');

    // сохраняем начальные состояния стилей 'body' и 'fixedHeader'
    const originalOverflow = document.body.style.overflow;
    const originalPaddingRight = document.body.style.paddingRight;
    const originalFixedHeaderPaddingRight = fixedHeader ? fixedHeader.style.paddingRight : '0';

    // получаем ширину скроллбара
    const root = document.getElementById('__next');
    const scrollBarWidth = root ? window.innerWidth - root.offsetWidth : 0;

    // блокируем скролл
    document.body.style.overflow = 'hidden';

    // компенсируем ширину окна при выключении скролла
    if (scrollBarWidth && withWindowWidth) {
      document.body.style.paddingRight = `${scrollBarWidth}px`;
      if (fixedHeader) {
        fixedHeader.style.paddingRight = `${scrollBarWidth}px`;
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.style.overflow = originalOverflow;

      if (scrollBarWidth && withWindowWidth) {
        document.body.style.paddingRight = originalPaddingRight;
        if (fixedHeader) {
          fixedHeader.style.paddingRight = originalFixedHeaderPaddingRight;
        }
      }
    };
  }, [locked]);

  // обновляем состояние, если начальное значение изменяется
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
  }, [initialLocked]);

  return { locked, setLocked };
};
