import { useEffect, useState } from 'react';

import { BREAKPOINT } from 'core/constants/breakpoints';
import { getClientHeight, getClientWidth } from 'core/utils/common';

function getWindowDimensions() {
  const width = getClientWidth();
  const height = getClientHeight();
  return { width, height };
}

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (!dimensions.width) {
      setDimensions(getWindowDimensions());
    }

    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getWindowDimensions]);

  return dimensions;
};

export const useBreakpoints = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isSmallDesktop, setIsSmallDesktop] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width && width <= BREAKPOINT.small) {
      setIsMobile(true);
      setIsTablet(false);
      setIsSmallDesktop(false);
      setIsDesktop(false);
    } else if (width && width <= BREAKPOINT.medium && width > BREAKPOINT.small) {
      setIsMobile(false);
      setIsTablet(true);
      setIsSmallDesktop(false);
      setIsDesktop(false);
    } else if (width && width > BREAKPOINT.medium && width < BREAKPOINT.large) {
      setIsMobile(false);
      setIsTablet(false);
      setIsSmallDesktop(true);
      setIsDesktop(false);
    } else {
      setIsMobile(false);
      setIsTablet(false);
      setIsSmallDesktop(false);
      setIsDesktop(true);
    }
  }, [width]);

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop
  };
};
