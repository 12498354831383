import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { BookingInterval } from 'core/entities/booking';
import { APIService } from 'core/services';
import { formatDay } from 'core/utils/calendar';

export interface Availability {
  minDuration: number;
  occupiedIntervals: BookingInterval[];
  dailyDurations: { [key: string]: number };
}

export class AvailabilityService extends APIService {
  public async fetch(flatId: number): Promise<Availability> {
    const response: AxiosResponse<Availability> = await this.execute(`/api/v2/flats/${flatId}/availability`);
    return {
      minDuration: get(response, 'data.minDuration', 1),
      occupiedIntervals: response.data.occupiedIntervals.map((interval) => ({
        arrival: new Date(new Date(get(interval, 'arrival', '')).setHours(0, 0, 0, 0)),
        departure: new Date(get(interval, 'departure', ''))
      })),
      dailyDurations: get(response, 'data.dailyDurations', {})
    };
  }

  public async check(flatId: number, arrival: Date, departure: Date): Promise<boolean> {
    const response: AxiosResponse<{ available: boolean }> = await this.execute(
      `/api/v2/flats/${flatId}/availability/check`,
      'GET',
      {
        params: {
          arrival: formatDay(arrival),
          departure: formatDay(departure)
        }
      }
    );
    return response.data.available;
  }
}
