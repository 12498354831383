import styles from './cancel-button.module.css';

import classNames from 'classnames';

interface CancelButtonProps {
  className?: string;
  onClick: () => void;
}

export const CancelButton = (props: CancelButtonProps) => (
  <button className={classNames(styles.root, props.className)} onClick={props.onClick}>
    Отменить
  </button>
);
