import get from 'lodash/get';

import { Currency } from 'core/entities';
import {
  BuildingTypesFilters,
  DatesFilters,
  FeaturesFilters,
  Filters,
  FiltersFeaturesNames,
  GuestsFilters,
  PricesFilters,
  RoomsExtraOptionsFilters,
  RoomsFilters
} from 'core/entities/filters';
import { longDateRangeFormat, shortDateRangeFormat } from 'core/utils/calendar';
import { WordForms } from 'core/utils/word-forms';

import { CheckedFilters } from 'hooks/filters';

export const getCalendarText = (dates: DatesFilters, variant: 'short' | 'long'): string => {
  if (!dates.arrival || !dates.departure) {
    return '';
  }
  const arrival = new Date(dates.arrival);
  const departure = new Date(dates.departure);
  if (variant === 'long') {
    return longDateRangeFormat(arrival, departure);
  }
  return shortDateRangeFormat(arrival, departure);
};

export const adultShortForms = ['взрослый', 'взрослых', 'взрослых'];
export const childForms = ['ребенок', 'ребенка', 'детей'];
export const babiesForms = ['младенец', 'младенца', 'младенцев'];

export const getGuestsText = (adults: number, kids = 0, babies = 0) => {
  const getPlural = (variants: Array<string>, value: number) =>
    new WordForms(variants[0], variants[1], variants[2]).getPlural(value, '\xa0', true);

  return {
    adults: getPlural(adultShortForms, adults),
    children: kids ? getPlural(childForms, kids) : null,
    babies: babies ? getPlural(babiesForms, babies) : null
  };
};

export function getGuestsTextAsSingleLine(guests: GuestsFilters): string {
  const { adults, children, babies } = getGuestsText(guests.adults, guests.children, guests.babies);
  const arr = [adults];
  if (children) {
    arr.push(`, ${children}`);
  }
  if (babies) {
    arr.push(`, ${babies}`);
  }
  return arr.join('');
}

export const getRoomText = (rooms: RoomsFilters, longFormat = false) => {
  const roomWord = longFormat ? ' комнатные' : ' комн.';
  let text = Object.values(rooms)
    .map((room, index) => {
      if (room) {
        if (index === 4) {
          return String(index + 1) + '+';
        }
        return String(index + 1);
      }
      return false;
    })
    .filter((item) => item)
    .join(', ');

  if (text.length) {
    text = text + roomWord;
  }

  return text;
};

export const getRoomsExtraOptionsText = (roomsExtraOptions: RoomsExtraOptionsFilters) => {
  const arr = [];
  if (roomsExtraOptions.kitchen) {
    arr.push('личная или общая кухня');
  }
  if (roomsExtraOptions.bathroom) {
    arr.push('личный санузел');
  }
  return arr.join(', ');
};

export const getTypeText = (buildingTypes: BuildingTypesFilters, withCapitalLetter = false) => {
  const arr = [];
  if (buildingTypes.flat) {
    arr.push('квартиры');
  }
  if (buildingTypes.cottage) {
    arr.push('дома');
  }
  if (buildingTypes.room) {
    arr.push('комнаты');
  }
  const text = arr.join(', ');
  if (withCapitalLetter) {
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }
  return text;
};

export const getSleepingPlacesText = (minBeds: number) => {
  if (minBeds > 1) {
    return `Кроватей не менее ${minBeds}`;
  }
  return '';
};

export const getPriceText = (prices: PricesFilters, currency: Currency) => {
  const minText = `от ${prices.filtered.min} ${currency.entity}`;
  const maxText = `до ${prices.filtered.max} ${currency.entity}`;
  const hasMinPrice = prices.filtered.min !== currency.minPrice;
  const hasMaxPrice = prices.filtered.max !== currency.maxPrice;
  let text = '';
  if (hasMinPrice) {
    text = minText;
  }
  if (hasMaxPrice) {
    text = maxText;
  }
  if (hasMinPrice && hasMaxPrice) {
    text = `${minText} ${maxText}`;
  }
  return text;
};

export const getFeaturesTextAsSingleLine = (features: FeaturesFilters) => {
  const arr = [];
  for (const [key, value] of Object.entries(features)) {
    if (value) {
      arr.push(get(FiltersFeaturesNames, key));
    }
  }
  return arr.join(', ').toLowerCase();
};

export const getFullFiltersText = (filters: Filters, chekedFilters: CheckedFilters, currency: Currency) => {
  const arr = [];
  if (chekedFilters.hasCheckedMap) {
    arr.push('в указанной области карты');
  }
  if (chekedFilters.hasChekedCalendar) {
    arr.push(getCalendarText(filters.dates, 'long'));
  }
  if (chekedFilters.hasChekedGuests) {
    arr.push(getGuestsTextAsSingleLine(filters.guests));
  }
  if (chekedFilters.hasCheckedBuildingTypes) {
    arr.push(getTypeText(filters.buildingTypes));
  }
  if (chekedFilters.hasCheckedRooms) {
    arr.push(getRoomText(filters.rooms, true));
  }
  if (chekedFilters.hasCheckedRoomsExtraOptions) {
    arr.push(getRoomsExtraOptionsText(filters.roomsExtraOptions));
  }
  if (filters.minBeds > 1) {
    arr.push(getSleepingPlacesText(filters.minBeds));
  }
  if (chekedFilters.hasCheckedPrice) {
    arr.push(getPriceText(filters.prices, currency));
  }
  if (chekedFilters.hasCheckedFeatures) {
    arr.push(getFeaturesTextAsSingleLine(filters.features));
  }
  const text = arr.join(', ');
  return text.substr(0, 1).toUpperCase() + text.substr(1).toLowerCase();
};
