import { useEffect } from 'react';

import { useSelectionValidator } from 'hooks/date-selection';
import { useDesktopHorizontalScroll } from 'hooks/scroll';

import { Calendar } from 'components/calendar/calendar';

interface CalendarWithScrollProps {
  showCalendarError?: (checkResult: number, minDuration: number) => void;
  noMinDuration?: boolean;
  noCheckValidation?: boolean;
}

export const CalendarWithScroll = ({
  showCalendarError,
  noMinDuration,
  noCheckValidation = false
}: CalendarWithScrollProps) => {
  const ref = useDesktopHorizontalScroll<HTMLDivElement>();

  const { checkResult: originalCheckResult, minDuration: originalMinDuration } = useSelectionValidator();

  const checkResult = noCheckValidation ? 0 : originalCheckResult;
  const minDuration = noCheckValidation ? 0 : originalMinDuration;

  useEffect(() => {
    if ((checkResult || checkResult === 0) && showCalendarError) {
      showCalendarError(checkResult, minDuration);
    }
  }, [checkResult, minDuration, showCalendarError]);

  let containsError = !!checkResult;
  if (noMinDuration && checkResult === 2) {
    containsError = false;
  }

  return <Calendar refObject={ref} containsError={containsError} />;
};
