import { BuildingTypesFilters, RoomsFilters } from 'core/entities/filters';

// для объектов со значением ключей true, false, undefined
export const checkIsObjectChanged = (
  firstObj: BuildingTypesFilters | RoomsFilters,
  secondObj: BuildingTypesFilters | RoomsFilters
) => {
  let isChanged = false;

  Object.entries(firstObj).forEach(([key, value]) => {
    // @ts-expect-error: разные ключи в разных типах объектов
    if (!!value !== !!secondObj[key]) {
      isChanged = true;
    }
  });

  return isChanged;
};
