import axios from 'axios';
import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import trimStart from 'lodash/trimStart';

import { YandexTokenInfo } from 'core/entities/auth-external';
import { PushInfo, PushMailingParams, RequestStatisticsQueryData } from 'core/entities/moderation';
import { TelegramUser } from 'core/entities/telegram';

interface Options<TypeParams, TypePayload, TypeHeaders> {
  params?: Optional<TypeParams>;
  payload?: Optional<TypePayload>;
  customHeaders?: Optional<TypeHeaders>;
  withCredentials?: Optional<boolean>;
  controller?: Optional<AbortController>;
}

export class APIService<
  Payload =
    | Record<string, unknown>
    | string
    | FormData
    | PushInfo
    | PushMailingParams
    | YandexTokenInfo
    | RequestStatisticsQueryData,
  Headers = Record<string, unknown>,
  Params = Record<string, unknown> | TelegramUser
> {
  host: string;
  correlationID: Optional<string>;
  accessToken: Optional<string>;
  appToken: Optional<string>;

  constructor(
    host: Optional<string>,
    correlationID: Optional<string> = null,
    accessToken: Optional<string> = null,
    appToken: Optional<string> = null
  ) {
    this.host = host || 'kvartirka.com';
    this.correlationID = correlationID || '';
    this.accessToken = accessToken;
    this.appToken = appToken;
  }

  protected constructURL(path: string): string {
    return `https://${this.host}/${trimStart(path, '/')}`;
  }

  protected execute = async (
    path: string,
    method: Method = 'GET',
    options: Options<Params, Payload, Headers> = {
      params: null,
      payload: null,
      customHeaders: null,
      withCredentials: false,
      controller: null
    }
  ): Promise<AxiosResponse> => {
    const headers: {
      'Content-Type': string;
      'X-Correlation-ID'?: string;
      'X-Access-Token'?: string;
      'Application-Session-ID'?: string;
    } = { 'Content-Type': 'application/json', ...options.customHeaders };

    if (this.correlationID) {
      headers['X-Correlation-ID'] = this.correlationID;
    }

    if (this.accessToken) {
      headers['X-Access-Token'] = this.accessToken;
    }

    if (this.appToken) {
      headers['Application-Session-ID'] = String(this.appToken);
    }

    const config: AxiosRequestConfig = {
      headers: headers
    };

    if (options.params) {
      config.params = options.params;
    }

    if (options.withCredentials) {
      config.withCredentials = true;
      config.xsrfCookieName = 'csrftoken';
      config.xsrfHeaderName = 'X-CSRFToken';
    }

    const url = this.constructURL(path);
    const response = await axios({ method, url, data: options.payload, ...config, signal: options.controller?.signal });

    return response;
  };
}
