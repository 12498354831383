import { addDays, isSameDay } from 'date-fns';
import * as React from 'react';

import { BookingInterval } from 'core/entities/booking';
import { Availability, AvailabilityService } from 'core/services/availability';
import { compareDays, formatDay } from 'core/utils/calendar';
import { reportError } from 'core/utils/error-mapper';

import { CommonContext } from 'contexts/common';

import { useProcessing } from 'hooks';

interface AvailabilityState {
  processing?: boolean;
  availability?: Availability;
}

const AvailabilityContext: React.Context<AvailabilityState> = React.createContext({});

interface AvailabilityProviderProps {
  flatId: number;
  removed?: boolean;
  children?: React.ReactChild | React.ReactChild[];
}

export enum IntervalCheck {
  free = 0,
  booked = 1,
  bookedDay = 2,
  shortDuration = 3,
  shortDurationBeforeBlockedDay = 4,
  onlyForDeparture = 5
}

export const AvailabilityProvider: React.FunctionComponent<AvailabilityProviderProps> = (props) => {
  const { context } = React.useContext(CommonContext);
  const mainHost = context && context.host ? context.host : '';
  const availabilityService = React.useMemo(() => new AvailabilityService(mainHost), [mainHost]);
  const { processing, start, stop } = useProcessing();
  const [availability, setAvailability] = React.useState<Optional<Availability>>(null);

  React.useEffect(() => {
    async function fetchInfo() {
      if (!props.removed) {
        try {
          start();
          setAvailability(null);
          await availabilityService.fetch(props.flatId).then(setAvailability);
        } catch (e) {
          reportError(e as Error);
        } finally {
          stop();
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInfo();
  }, [props.flatId, props.removed]);

  return React.createElement(AvailabilityContext.Provider, {
    children: props.children,
    value: { processing, availability: availability as Availability }
  });
};

function getMinDurationForArrival(arrival: Date, availability?: Availability): number {
  if (!availability) {
    return 1;
  }
  const dailyDuration = availability.dailyDurations[formatDay(arrival)];
  return dailyDuration ? dailyDuration : availability.minDuration;
}

function intervalsLockedForArrival(availability?: Availability): BookingInterval[] {
  if (!availability) {
    return [];
  }
  return availability.occupiedIntervals.map((interval) => {
    return {
      arrival: interval.arrival,
      departure: interval.departure
    };
  });
}

function isArrivalBlockedBy(day: Date, interval: BookingInterval) {
  return compareDays(day, interval.arrival) >= 0 && compareDays(day, interval.departure) < 0;
}

function isDepartureBlockedBy(arrival: Date, departure: Date, interval: BookingInterval) {
  return (
    (compareDays(departure, interval.arrival) > 0 && compareDays(departure, interval.departure) <= 0) ||
    // случай с поглощением интервала
    (compareDays(arrival, interval.arrival) < 0 && compareDays(departure, interval.departure) > 0)
  );
}

function isArrivalBlocked(arrival: Date, blackList?: BookingInterval[]) {
  if (!blackList) {
    return false;
  }
  return blackList.some((interval) => isArrivalBlockedBy(arrival, interval));
}

function isDepartureBlocked(arrival: Date, departure: Date, availability?: Availability) {
  if (!availability) {
    return IntervalCheck.free;
  }
  if (departure < addDays(arrival, getMinDurationForArrival(arrival, availability))) {
    return IntervalCheck.shortDuration;
  }
  if (availability.occupiedIntervals.some((interval) => isDepartureBlockedBy(arrival, departure, interval))) {
    return IntervalCheck.booked;
  }
  return IntervalCheck.free;
}

function closestArrival(day: Date, intervals?: BookingInterval[]): Date {
  let neighbour = null;
  if (intervals) {
    neighbour = intervals.find((interval) => isSameDay(day, interval.arrival) || interval.arrival > day);
  }
  return neighbour ? neighbour.arrival : day;
}

export interface AvailabilityChecks {
  isArrivalBlocked: (arrival: Date) => boolean;
  isDepartureBlocked: (arrival: Date, departure: Date) => IntervalCheck;
  isBooked: (date: Date) => boolean;
  isStartOfBooking: (date: Date) => boolean;
  closestBlockedDay: (arrival: Date) => Date;
  getMinDuration: (arrival: Date) => number;
}

const everythingIsBlocked: AvailabilityChecks = {
  isArrivalBlocked: () => true,
  isDepartureBlocked: () => IntervalCheck.booked,
  isBooked: () => false,
  isStartOfBooking: () => false,
  closestBlockedDay: (arrival: Date) => arrival,
  getMinDuration: () => 1
};

export function useAvailability(): AvailabilityChecks {
  const { availability, processing } = React.useContext(AvailabilityContext);
  if (!processing) {
    const arrivalChecks = React.useMemo(() => intervalsLockedForArrival(availability), [availability]);
    const intervals = availability && availability.occupiedIntervals;
    return {
      isArrivalBlocked: React.useCallback((arrival: Date) => isArrivalBlocked(arrival, arrivalChecks), [arrivalChecks]),
      isDepartureBlocked: React.useCallback(
        (arrival: Date, departure: Date) => isDepartureBlocked(arrival, departure, availability),
        [availability]
      ),
      isBooked: React.useCallback((date: Date) => isArrivalBlocked(date, intervals), [intervals]),
      isStartOfBooking: React.useCallback(
        (date: Date) => (intervals ? intervals.some((interval) => isSameDay(date, interval.arrival)) : false),
        [intervals]
      ),
      closestBlockedDay: React.useCallback((day: Date) => closestArrival(day, intervals), [intervals]),
      getMinDuration: React.useCallback(
        (arrival: Date) => getMinDurationForArrival(arrival, availability),
        [availability]
      )
    };
  }
  return everythingIsBlocked;
}

export function useMinDuration(): number {
  const context = React.useContext(AvailabilityContext);
  return (context.availability && context.availability.minDuration) || 1;
}
