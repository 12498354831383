// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT } from 'core/constants/filters';
import { Currency } from 'core/entities';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { checkIsObjectChanged } from 'core/utils/filters/checkIsObjectChanged';
import { getRoomText, getSleepingPlacesText, getTypeText } from 'core/utils/filters/filters-info';

import { useHasChekedFilters } from 'hooks/filters';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { Button } from 'components/common/button/button';
import { CheckboxEvent } from 'components/common/checkbox/checkbox';
import { Dropdown, useDropdown } from 'components/common/dropdown/dropdown';
import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { BuildingTypesFilter } from 'components/search/filters/building-types/building-types';
import { RoomsFilter } from 'components/search/filters/rooms/rooms';
import { RoomsExtraOptionsFilter } from 'components/search/filters/rooms-extra-options/rooms-extra-options';
import { SleepingPlacesFilter } from 'components/search/filters/sleeping-places/sleeping-places';

const defaultButtonText = 'Жильё';

interface BuildingTypesControlProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  currency: Currency;
  adultChecked: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const BuildingTypesControl = ({
  filters,
  setFilters,
  currency,
  adultChecked,
  isLoading,
  setLoading
}: BuildingTypesControlProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { opened, onOpen, onClose } = useDropdown(dropdownRef);

  const [buildingTypes, setBuildingTypes] = useState(filters.buildingTypes);
  const [rooms, setRooms] = useState(filters.rooms);
  const [roomsExtraOptions, setRoomsExtraOptions] = useState(filters.roomsExtraOptions);
  const [minBeds, setMinBeds] = useState(filters.minBeds);
  const [buttonText, setButtonText] = useState(defaultButtonText);

  const { isSmallDesktop } = useBreakpoints();
  const { hasCheckedBuildingTypes, hasCheckedSleepingPlaces, hasCheckedRooms } = useHasChekedFilters(
    filters,
    currency,
    adultChecked
  );

  // sync filters
  useEffect(() => {
    setBuildingTypes(filters.buildingTypes);
    setRooms(filters.rooms);
    setRoomsExtraOptions(filters.roomsExtraOptions);
    setMinBeds(filters.minBeds);
  }, [filters]);

  // эффект установки текста кнопки
  useEffect(() => {
    const roomsText = getRoomText(filters.rooms);
    const typeText = getTypeText(filters.buildingTypes, true);

    if (!hasCheckedBuildingTypes) {
      setButtonText(hasCheckedRooms ? roomsText : defaultButtonText);
    } else {
      setButtonText(hasCheckedRooms ? `${typeText}, ${roomsText}` : typeText);
    }

    if (!hasCheckedBuildingTypes && !hasCheckedRooms && hasCheckedSleepingPlaces) {
      setButtonText(getSleepingPlacesText(filters.minBeds));
    }
  }, [filters, hasCheckedRooms, hasCheckedBuildingTypes, hasCheckedSleepingPlaces, getRoomText, getTypeText]);

  // эффект ограничения длинны текста в кнопке
  useEffect(() => {
    if (buttonText.length >= 24) {
      setButtonText(`${buttonText.slice(0, 20)}...`);
    }
  }, [buttonText]);

  // эффект установки фильтров в зависимости от других фильтров
  useEffect(() => {
    if (!buildingTypes.room) {
      setRoomsExtraOptions({
        bathroom: false,
        kitchen: false
      });
    }
    if (buildingTypes.room && !buildingTypes.flat && !buildingTypes.cottage) {
      setRooms({
        one: false,
        two: false,
        three: false,
        four: false,
        five: false
      });
    }
  }, [buildingTypes]);

  const handleBuildingTypesChange = useCallback(
    (event: CheckboxEvent) => {
      setBuildingTypes({
        ...buildingTypes,
        [event.target.name]: event.target.checked
      });
    },
    [filters, buildingTypes]
  );

  const handleRoomsChange = useCallback(
    (event: CheckboxEvent) => {
      setRooms({
        ...rooms,
        [event.target.name]: event.target.checked
      });
    },
    [filters, rooms]
  );

  const handleRoomsExtraOptionChange = useCallback(
    (event: CheckboxEvent) => {
      setRoomsExtraOptions({
        ...roomsExtraOptions,
        [event.target.name]: event.target.checked
      });
    },
    [filters, roomsExtraOptions]
  );

  const handleMinBedsChange = useCallback(
    (value: number) => {
      setMinBeds(value);
    },
    [filters]
  );

  const handleApply = useCallback(() => {
    // сравнивание не через isEqual, т.к. поля могут быть true, false, undefined
    const isBuildingTypesChanged = checkIsObjectChanged(buildingTypes, filters.buildingTypes);
    const isRoomsChanged = checkIsObjectChanged(rooms, filters.rooms);

    if (
      !isBuildingTypesChanged &&
      !isRoomsChanged &&
      isEqual(roomsExtraOptions, filters.roomsExtraOptions) &&
      minBeds === filters.minBeds
    ) {
      onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }
    const updatedFilters: Filters = {
      ...filters,
      buildingTypes,
      rooms,
      roomsExtraOptions,
      minBeds
    };
    if (setLoading) {
      setLoading((prev) => ({ ...prev, buildingTypes: true }));
    }
    setFilters(updatedFilters);
    onClose();
  }, [filters, buildingTypes, rooms, roomsExtraOptions, minBeds]);

  useEffect(() => {
    setBuildingTypes(filters.buildingTypes);
    setRooms(filters.rooms);
    setRoomsExtraOptions(filters.roomsExtraOptions);
    setMinBeds(filters.minBeds);
  }, [opened]);

  const showRoomsFilter = !(!(buildingTypes.flat || buildingTypes.cottage) && buildingTypes.room);

  return (
    <div className={styles.wrapper}>
      <DropdownButton
        className={styles.btn}
        text={buttonText}
        size='md'
        isActive={opened}
        onClick={onOpen}
        compact={isSmallDesktop && (hasCheckedBuildingTypes || hasCheckedSleepingPlaces || hasCheckedRooms)}
        compactText='Жильё...'
        isLoading={isLoading ? isLoading.buildingTypes : false}
      />
      <Dropdown className={styles.dropdown} ref={dropdownRef} opened={opened}>
        <div className={styles.dropdownContent}>
          <BuildingTypesFilter buildingTypes={buildingTypes} onChange={handleBuildingTypesChange} />
          {showRoomsFilter && <RoomsFilter rooms={rooms} onChange={handleRoomsChange} />}
          <SleepingPlacesFilter minBeds={minBeds} onChangeMinBeds={handleMinBedsChange} />
          {buildingTypes.room && (
            <RoomsExtraOptionsFilter roomsExtraOptions={roomsExtraOptions} onChange={handleRoomsExtraOptionChange} />
          )}
          <Button text={APPLY_FILTER_BUTTON_TEXT} onClick={handleApply} block />
        </div>
      </Dropdown>
    </div>
  );
};
