import { Text } from 'components/common/text/text';

interface FooterCopyrightProps {
  className?: string;
  slim?: boolean;
}

export const FooterCopyright = (props: FooterCopyrightProps) => {
  const currentYear = new Date().getFullYear();
  return (
    <Text As='p' size={16}>
      &copy;&nbsp;2007&ndash;{currentYear}&nbsp;ООО &laquo;Стратегия роста&raquo;
      {!props.slim && ', зарегистрированный товарный знак «Квартирка».'}
    </Text>
  );
};
