// eslint-disable-next-line import/no-relative-parent-imports
import styles from '../controls.module.css';

import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { APPLY_FILTER_BUTTON_TEXT, COMPACT_BUTTON_TEXT, DEFAULT_BUTTON_TEXT } from 'core/constants/filters';
import { Currency } from 'core/entities';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { convertCurrencyValue } from 'core/utils/currency';

import { useHasChekedFilters } from 'hooks/filters';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { Button } from 'components/common/button/button';
import { Dropdown, useDropdown } from 'components/common/dropdown/dropdown';
import { DropdownButton } from 'components/common/dropdown-button/dropdown-button';
import { Price } from 'components/common/price/price';
import { PricesFilter } from 'components/search/filters/prices/prices';

interface PricesControlProps {
  filters: Filters;
  isLandingWithPrice: boolean;
  setFilters: (filters: Filters) => void;
  currencies: {
    current: Currency;
    primary: Currency;
  };
  adultChecked: boolean;
  isLoading?: FiltersLoadingType;
  setLoading?: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const PricesControl = ({
  filters,
  isLandingWithPrice,
  setFilters,
  currencies,
  adultChecked,
  isLoading,
  setLoading
}: PricesControlProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { opened, onOpen, onClose } = useDropdown(dropdownRef);

  const [touched, setTouched] = useState(false);
  const [isLandingWithPricePage, setIsLandingWithPricePage] = useState(isLandingWithPrice);
  const [price, setPrice] = useState([filters.prices.filtered.min, filters.prices.filtered.max]);
  const [buttonText, setButtonText] = useState<ReactNode>(DEFAULT_BUTTON_TEXT);

  const { isSmallDesktop } = useBreakpoints();
  const { hasCheckedPrice, hasCheckedBuildingTypes, hasCheckedSleepingPlaces, hasCheckedRooms } = useHasChekedFilters(
    filters,
    currencies.current,
    adultChecked
  );

  useEffect(() => {
    // sync filters
    setPrice([filters.prices.filtered.min, filters.prices.filtered.max]);

    if (!hasCheckedPrice) {
      setButtonText(DEFAULT_BUTTON_TEXT);
    } else {
      setButtonText(
        <>
          {!touched && isLandingWithPricePage
            ? convertCurrencyValue(filters.prices.filtered.min, currencies.current, currencies.primary)
            : filters.prices.filtered.min}
          ...
          <Price
            currency={currencies.current}
            value={
              !touched && isLandingWithPricePage
                ? convertCurrencyValue(filters.prices.filtered.max, currencies.current, currencies.primary)
                : filters.prices.filtered.max
            }
          />
        </>
      );
    }
  }, [filters, hasCheckedPrice]);

  const handleChange = useCallback((value: Array<number>) => {
    setTouched(true);
    setIsLandingWithPricePage(false);
    setPrice([value[0], value[1]]);
  }, []);

  const handleApply = useCallback(() => {
    if (price[0] === filters.prices.filtered.min && price[1] === filters.prices.filtered.max) {
      onClose();
      return;
    }

    if (setLoading) {
      setLoading(DEFAULT_LOADINGS);
    }
    const updatedFilters: Filters = {
      ...filters,
      prices: {
        ...filters.prices,
        filtered: {
          min: price[0],
          max: price[1]
        }
      }
    };
    if (setLoading) {
      setLoading((prev) => ({ ...prev, prices: true }));
    }
    setFilters(updatedFilters);
    onClose();
  }, [filters, price]);

  const handleClick = useCallback(() => {
    if (opened) {
      handleApply();
    } else {
      onOpen();
    }
  }, [opened]);

  useEffect(() => {
    setPrice([filters.prices.filtered.min, filters.prices.filtered.max]);
  }, [opened]);

  return (
    <div className={styles.wrapper}>
      <DropdownButton
        text={buttonText}
        size='md'
        isActive={opened}
        onClick={handleClick}
        compact={
          isSmallDesktop && hasCheckedPrice && (hasCheckedBuildingTypes || hasCheckedSleepingPlaces || hasCheckedRooms)
        }
        compactText={COMPACT_BUTTON_TEXT}
        isLoading={isLoading ? isLoading.prices : false}
      />
      <Dropdown className={styles.dropdown} ref={dropdownRef} opened={opened}>
        <div className={styles.dropdownContent}>
          <PricesFilter
            className={styles.filter}
            value={price}
            onChange={handleChange}
            currencies={currencies}
            isLandingWithPrice={isLandingWithPricePage}
            filters={filters}
          />
          <Button text={APPLY_FILTER_BUTTON_TEXT} onClick={handleApply} block />
        </div>
      </Dropdown>
    </div>
  );
};
