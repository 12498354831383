import { AxiosResponse } from 'axios';

import { GeocoderObject } from 'core/entities/geo';
import { APIService } from 'core/services/index';

export class GeocoderService extends APIService {
  public async fetchByQuery(query: string): Promise<GeocoderObject> {
    const response: AxiosResponse<GeocoderObject> = await this.execute('/api/v3/context/geocoder', 'GET', {
      params: { query }
    });
    return this.processResponse(response);
  }

  public async fetchByCoords(lng: number, lat: number): Promise<GeocoderObject> {
    const response: AxiosResponse<GeocoderObject> = await this.execute('/api/v3/context/geocoder', 'GET', {
      params: { lng, lat }
    });
    return this.processResponse(response);
  }

  private processResponse(response: AxiosResponse<GeocoderObject>): GeocoderObject {
    return response.data;
  }
}
