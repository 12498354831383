import styles from './header-back-link.module.css';

import ArrowIcon from 'components/common/icons/arrow.svg';
import CustomLink from 'components/common/link/link';

export interface HeaderBackLinkProps {
  href: string;
  text: string;
  external?: boolean;
  image?: string;
}

export function HeaderBackLink({ href, text, external = true, image }: HeaderBackLinkProps) {
  return (
    <CustomLink className={styles.root} href={href} external={external}>
      <>
        <div className={styles.arrowIcon}>
          <ArrowIcon />
        </div>
        {image && <img src={image} alt={text} className={styles.image} />}
        {text}
      </>
    </CustomLink>
  );
}
