import styles from './search-bar.module.css';

import SearchIcon from './icons/search.svg';

import classNames from 'classnames';
import { useEffect, useContext } from 'react';

import { City } from 'core/entities/geo';

import { SearchBarContext, SearchBarMode } from 'contexts/search/search-bar';

import { useSearchBar } from 'hooks/search-bar';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { Spinner } from 'components/common/spinner/spinner';
import { Content } from 'components/search-bar/content/content';
import { FindButton } from 'components/search-bar/find-button/find-button';
import { ResetButton } from 'components/search-bar/reset-button/reset-button';
import { SearchInput } from 'components/search-bar/search-input/search-input';
import { Suggests } from 'components/search-bar/suggests/suggests';

interface SearchBarProps {
  classname?: string;
  children?: React.ReactChild;
  mainHost: string;
  city?: Optional<City>;
  isCentral?: boolean;
  setSearchbarActive?: (param: boolean) => void;
  stdPlaceholder?: boolean;
  onSubmit?: (url: string, isMapUrl?: boolean) => void;
}

export const SearchBar = (props: SearchBarProps) => {
  const { classname, children, mainHost, city, isCentral, setSearchbarActive, stdPlaceholder, onSubmit } = props;
  const searchBarContext = useContext(SearchBarContext);
  const searchBar = useSearchBar(mainHost, isCentral, onSubmit);
  const { isMobile } = useBreakpoints();

  const showSpinner = searchBar.isTransitionLoading || searchBar.isSearchLoading;
  const isActive = searchBarContext.mode === SearchBarMode.ACTIVE;

  useEffect(() => {
    if (isCentral && isMobile) {
      if (setSearchbarActive) {
        setSearchbarActive(isActive);
      }
    }
  }, [isMobile, isActive, isCentral]);

  return (
    <div className={classNames(styles.root, classname)} ref={searchBar.searchBarRef}>
      <div className={classNames(styles.wrapper, { [styles.active]: isActive }, { [styles.central]: isCentral })}>
        <SearchIcon className={classNames(styles.icon, { [styles.central]: isCentral })} />
        <SearchInput
          searchBarContext={searchBarContext}
          searchBar={searchBar}
          city={city}
          isActive={isActive}
          isCentral={isCentral}
          stdPlaceholder={stdPlaceholder}
        />
        {showSpinner && (
          <div className={styles.spinner}>
            <Spinner size={isMobile ? 16 : 24} />
          </div>
        )}
        <ResetButton
          onClick={searchBar.handleReset}
          searchBarMode={searchBarContext.mode}
          showSpinner={showSpinner}
          isSearchBarContext={searchBarContext.value.length > 0}
        />
        <FindButton show={!!isCentral} onClick={searchBar.handlePressEnter} />
        {!isMobile && (
          <Suggests
            suggests={searchBarContext.suggests}
            currentSuggestIndex={searchBar.currentSuggestIndex}
            show={searchBarContext.showSuggests}
            onClickSuggest={searchBar.handleClickSuggest}
            onMouseEnterSuggest={searchBar.handleMouseEnterSuggest}
          />
        )}
      </div>
      <Content
        isActive={isActive}
        isSearchLoading={searchBar.isSearchLoading}
        isTransitionLoading={searchBar.isTransitionLoading}
        onCancel={searchBar.handleCancel}
        isCentral={!!isCentral}
        isMobile={isMobile}
      >
        {children}
      </Content>
      {isMobile && (
        <Suggests
          suggests={searchBarContext.suggests}
          show={searchBarContext.showSuggests}
          isMobile
          onClickSuggest={searchBar.handleClickSuggest}
          isCentral={isCentral}
        />
      )}
    </div>
  );
};
