import styles from './footer-info.module.css';

import classNames from 'classnames';

import CustomLink from 'components/common/link/link';
import { Text } from 'components/common/text/text';
import { FooterCopyright } from 'components/layout/footer/footer-copyright/footer-copyright';
import MasterCardIcon from 'components/layout/footer/icons/master-card.svg';
import MirIcon from 'components/layout/footer/icons/mir.svg';
import UnionPayIcon from 'components/layout/footer/icons/union-pay.svg';
import VisaIcon from 'components/layout/footer/icons/visa.svg';

interface FooterInfoProps {
  className?: string;
}

export const FooterInfo = (props: FooterInfoProps) => {
  return (
    <div className={classNames(styles.root, props.className)}>
      <div className={styles.info}>
        <FooterCopyright className={styles.copy} />
        <CustomLink href='/info/legal/' variant='black' underlined>
          Правовая информация
        </CustomLink>
      </div>
      <div className={styles.payments}>
        <Text As='p' size={16}>
          Платежи защищены
        </Text>
        <ul className={styles.paymentsList}>
          <li className={styles.paymentsItem}>
            <MirIcon />
          </li>
          <li className={styles.paymentsItem}>
            <VisaIcon />
          </li>
          <li className={styles.paymentsItem}>
            <MasterCardIcon />
          </li>
          <li className={styles.paymentsItem}>
            <UnionPayIcon />
          </li>
        </ul>
      </div>
    </div>
  );
};
