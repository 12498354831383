import classNames from 'classnames';

interface Classes {
  default: string;
  hasCheckOnLeft: string;
  hasCheckOnRight: string;
}

export const getMulticheckboxClasses = (checkboxes: Array<boolean>, classes: Classes, index: number) => {
  return classNames(
    classes.default,
    { [classes.hasCheckOnLeft]: Boolean(checkboxes[index - 1]) },
    { [classes.hasCheckOnRight]: Boolean(checkboxes[index + 1]) }
  );
};
