import styles from './week.module.css';

import classNames from 'classnames';

import { Day } from 'components/calendar/day/day';

interface WeekProps {
  dates: Date[];
  past?: boolean;
  first?: boolean;
  containsError?: boolean;
}

const weekSize = (size: number) => styles[`weekSize-${size}`];

export const Week = (props: WeekProps) => {
  const startStubs = [];
  const endStubs = [];
  const lastWeekDay = props.dates[props.dates.length - 1].getDay();
  const firstWeekDay = props.dates[0].getDay();

  // Костыль для случая "последняя и неполная неделя, первая тоже неполная"
  if (!props.past && lastWeekDay) {
    for (let i = lastWeekDay; i < 7; i = i + 1) {
      endStubs.push(<span className={styles.stub} key={`end_stub_${i}`} />);
    }
  }

  // Костыль для прошедшей части первой неполной недели
  if (props.past && props.first) {
    const stubsNeeded = firstWeekDay ? firstWeekDay - 1 : 6;
    for (let i = 0; i < stubsNeeded; i = i + 1) {
      startStubs.push(<span className={styles.stub} key={`start_stub_${i}`} />);
    }
  }

  const className = classNames(styles.root, weekSize(props.dates.length + endStubs.length + startStubs.length), {
    [styles.firstWeek]: props.first,
    [styles.pastWeek]: props.past
  });

  return (
    <div className={className}>
      {startStubs}
      {props.dates.map((date, idx) => {
        const first = !idx;
        const last = idx === props.dates.length - 1;
        const ragged = (first && date.getDay() !== 1) || (last && date.getDay() !== 0);
        return (
          <Day
            date={date}
            key={`day_${date.valueOf()}`}
            first={first}
            last={last}
            ragged={ragged}
            hasError={props.containsError}
          />
        );
      })}
      {endStubs}
    </div>
  );
};
