import styles from './reset-button.module.css';

import { SearchBarMode } from 'contexts/search/search-bar';

import ResetIcon from 'components/search-bar/icons/reset.svg';

interface ResetButtonProps {
  searchBarMode: SearchBarMode;
  isSearchBarContext: boolean;
  showSpinner: boolean;
  onClick: () => void;
}

export const ResetButton = ({ searchBarMode, isSearchBarContext, showSpinner, onClick }: ResetButtonProps) => {
  if (searchBarMode === SearchBarMode.WITH_VALUE || (searchBarMode === SearchBarMode.ACTIVE && isSearchBarContext)) {
    if (!showSpinner) {
      return (
        <button className={styles.root} onClick={onClick} type='reset'>
          <ResetIcon />
        </button>
      );
    }
  }
  return null;
};
