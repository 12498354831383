import styles from './map-button.module.css';

import ListIcon from './list.svg';
import MapIcon from './map.svg';

import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { Filters } from 'core/entities/filters';

import { useFlatsRoutes } from 'hooks/router/use-flats-routes';

import { Spinner } from 'components/common/spinner/spinner';

interface MapButtonProps {
  className?: string;
  mainHost: string;
  filters: Filters;
}

export const MapButton = ({ className, mainHost, filters }: MapButtonProps) => {
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const { isMapPage, goToFlatsMapPage, goToFlatsListPage } = useFlatsRoutes(mainHost);

  const handleClickListButton = useCallback(() => {
    setIsListLoading(true);
    goToFlatsListPage(filters);
  }, [goToFlatsListPage, filters]);

  const handleClickMapButton = useCallback(() => {
    setIsMapLoading(true);
    goToFlatsMapPage(filters);
  }, [goToFlatsMapPage, filters]);

  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={classNames(styles.button, styles.list, { [styles.active]: !isMapPage })}
        onClick={handleClickListButton}
      >
        {isListLoading ? <Spinner classname={styles.icon} size={16} /> : <ListIcon className={styles.icon} />}
        <span className={styles.content}>Список</span>
      </div>
      <div
        className={classNames(styles.button, styles.map, { [styles.active]: isMapPage })}
        onClick={handleClickMapButton}
      >
        {isMapLoading ? <Spinner classname={styles.icon} size={16} /> : <MapIcon className={styles.icon} />}
        <span className={styles.content}>Карта</span>
      </div>
    </div>
  );
};
